import { useCallback } from 'react';

export const useUIHandlers = (setInputText, onSendClick, setCompletions) => {
  const handleChange = useCallback(
    (event) => {
      setInputText(event);
    },
    [setInputText],
  );

  const handleKeyPress = useCallback(
    (e) => {
      if (e.keyCode === 13) {
        onSendClick(e);
      }
    },
    [onSendClick],
  );

  const handleSuggestionsClick = useCallback(
    (e) => {
      setInputText(e.target.value);
      setCompletions([]);
    },
    [setInputText, setCompletions],
  );

  return {
    handleChange,
    handleKeyPress,
    handleSuggestionsClick,
  };
};
