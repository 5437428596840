/**
 * Parses substance properties from markdown text
 * @param {string} markdown - Markdown text containing substance links
 * @returns {Array<{title: string, url: string, description: string, wdtId: string}>}
 */
export function parseSubstanceProperties(markdown) {
  if (!markdown) return [];
  const linkRegex = /\[(.+?)\]\((.+?)\)/g;
  const matches = [...markdown.matchAll(linkRegex)];

  return matches.map((match) => {
    const bracketText = match[1];
    const url = match[2];
    const [title, ...rest] = bracketText.split('—');
    let wdtId = '';

    if (url.includes('wikidata.org/entity/')) {
      wdtId = url.split('/').pop().trim();
    }

    return {
      title: (title || '').trim(),
      url: url.trim(),
      description: rest.join('—').trim(),
      wdtId,
    };
  });
}
