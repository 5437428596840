import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import './SubstancePropertyModal.css';

// todo: display strucutre 2d image on of substances
const SubstancePropertyModal = ({ data, onClose }) => {
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') onClose();
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [onClose]);

  const handleOverlayClick = (e) => {
    if (e.target.className === 'substance-modal-overlay') onClose();
  };

  if (!data) return null;

  const metaData = data.meta || {};
  const propertyObj = metaData.properties_table?.Properties?.[0] || {};
  const firstDescription = metaData.description || metaData.description_list?.[0]?.Description;

  return ReactDOM.createPortal(
    <div className='container'>
      <div className='substance-modal-overlay' onClick={handleOverlayClick}>
        <div className='substance-modal-content' onClick={(e) => e.stopPropagation()}>
          <button className='close-button' onClick={onClose}>
            X
          </button>

          <div className='substance-modal-container'>
            <div className='modal-title'>
              <h2>
                {data.title}{' '}
                {propertyObj.MolecularFormula ? '(' + propertyObj.MolecularFormula + ')' : ''}
              </h2>
            </div>

            <div className='modal-content-wrapper'>
              <div className='substance-modal-left'>
                <p className='substance-description'>{firstDescription}</p>
                <div className='properties-grid'>
                  {Object.entries(propertyObj).map(([key, value]) => (
                    <React.Fragment key={key}>
                      <div className='property-key'>{key === 'CID' ? 'PubChem' + key : key}:</div>
                      <div className='property-value'>
                        {key === 'CID' ? (
                          <a
                            href={`https://pubchem.ncbi.nlm.nih.gov/compound/${value}`}
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            {value}
                          </a>
                        ) : key === 'MolecularWeight' ? (
                          value + ' (g/mol)'
                        ) : key === 'ExactMass' ? (
                          value + ' (Da)'
                        ) : (
                          value
                        )}
                      </div>
                    </React.Fragment>
                  ))}
                  <div className='property-key'>WikidataID:</div>
                  <div className='property-value'>
                    <a href={data.url} target='_blank' rel='noopener noreferrer'>
                      {data.wdtId}
                    </a>
                  </div>
                </div>
              </div>

              <div className='substance-modal-right'>
                {metaData.imageURL && (
                  <div className='image-container'>
                    <img
                      src={metaData.imageURL}
                      alt='Substance'
                      className='substance-modal-image-top'
                    />
                  </div>
                )}
                {metaData.structure_2d_url && (
                  <div className='image-container'>
                    <img
                      src={metaData.structure_2d_url}
                      alt='Structure 2D'
                      className='substance-modal-image-bottom'
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.getElementById('modal-root'),
  );
};

export default SubstancePropertyModal;
