import React, { createContext } from 'react';
import { useChatMessages } from '../hooks/useChatMessages';

export const ChatMessagesContext = createContext(null);

export const ChatMessagesProvider = ({ children }) => {
  const chatMessagesData = useChatMessages();
  return (
    <ChatMessagesContext.Provider value={chatMessagesData}>{children}</ChatMessagesContext.Provider>
  );
};
