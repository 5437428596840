import React from 'react';
import { InlineAutocomplete } from 'react-inline-autocomplete';
import 'react-inline-autocomplete/dist/index.css';
import SuggestionsSlider from '../common/slider/SuggestionSlider';
import SendIcon from '../../icons/SendIcon';
import useIsMobile from '../../hooks/useIsMobile';
import { useConsent } from '../../contexts/ConsentContext';

export default function ChatFooter({
  inputText,
  completions,
  handleChange,
  onInputKeyPress,
  inputRef,
  onSendClick,
  chatBegin,
  onSuggestionsClick,
  suggestions,
  t,
  loading,
}) {
  const isMobile = useIsMobile();
  const { hasNecessaryConsent, openConsentBanner } = useConsent();

  const isInputDisabled = !hasNecessaryConsent;

  return (
    <div
      className={`chat-footer ${chatBegin ? 'chat-begin-centered' : 'chat-bottom'}`}
      onKeyUp={onInputKeyPress}
    >
      {chatBegin && <h1 className='hello-message'>How can I assist you today?</h1>}

      <div className='bottom-wrapper bw-chat clearfix'>
        <div className='message-input-wrapper'>
          <InlineAutocomplete
            ref={inputRef}
            className='message-autocomplete'
            style={{
              border: 'none',
              height: '100%',
              boxSizing: 'border-box',
              width: '100%',
              position: 'absolute',
              outlineWidth: 0,
              paddingLeft: '11px',
              paddingTop: 0,
              paddingBottom: 0,
              fontSize: '16px',
              backgroundColor: '#f1f1f1',
              opacity: isInputDisabled ? '0.7' : '1',
            }}
            value={inputText}
            dataSource={completions}
            onChange={handleChange}
            caseSensitive={false}
            disabled={isInputDisabled}
            placeholder={isInputDisabled ? 'Please accept necessary cookies to use chat' : ''}
          />
        </div>
        <button
          className={`send-message ${
            loading || inputText.trim() === '' || isInputDisabled ? 'send-disabled' : ''
          }`}
          onClick={onSendClick}
          {...(!isMobile && { 'data-tooltip': 'Send' })}
          disabled={loading || inputText.trim() === '' || isInputDisabled}
        >
          <SendIcon />
        </button>
      </div>

      {isInputDisabled && (
        <div className='consent-required-notice'>
          <p>To use our chat feature, you need to accept necessary cookies for functionality.</p>
          <button className='cookie-settings-button' onClick={openConsentBanner}>
            Cookie Settings
          </button>
        </div>
      )}

      {chatBegin && suggestions && suggestions.length > 0 && (
        <div className='row' style={{ paddingLeft: '2vh', paddingRight: '2vh' }}>
          <div className='col col-lg-12' style={{ marginTop: '30px' }}>
            <SuggestionsSlider
              suggestions={suggestions}
              onSuggestionsClick={onSuggestionsClick}
              t={t}
            />
          </div>
        </div>
      )}
    </div>
  );
}
