import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './SuggestionSlider.css';
import useIsMobile from '../../../hooks/useIsMobile';

export default function SuggestionsSlider({ suggestions, onSuggestionsClick, t }) {
  const isMobile = useIsMobile();

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: isMobile ? 1 : 2,
    slidesToScroll: 1,
    arrows: true,
    draggable: true,
    swipeToSlide: true,
    touchMove: true,
  };

  return (
    <div className='suggestions-slider'>
      {suggestions && suggestions.length > 0 && (
        <Slider {...sliderSettings}>
          {suggestions.map((suggestion, index) => (
            <div key={index}>
              <button
                className='btn btn-primary btn-suggestion'
                value={suggestion}
                onClick={onSuggestionsClick}
                title={t('use_suggestion')}
              >
                {suggestion}
              </button>
            </div>
          ))}
        </Slider>
      )}
    </div>
  );
}
