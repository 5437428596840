import { useState, useEffect } from 'react';
import { fetchSuggestions } from '../services/api';

export const useSuggestions = (NUMBER_OF_SUGGESTIONS) => {
  const [suggestions, setSuggestions] = useState(null);

  const fetchDefaultSuggestions = async () => {
    const suggestions = await fetchSuggestions(NUMBER_OF_SUGGESTIONS);
    if (suggestions.length > 0) {
      setSuggestions(suggestions);
    }
  };

  useEffect(() => {
    if (suggestions === null) {
      fetchDefaultSuggestions();
    }
  }, [suggestions]);

  return { suggestions, setSuggestions };
};
