import React from 'react';
import './SkeletonButton.css';

const SkeletonButton = ({ width, height, className }) => (
  <button
    className={`substance-button skeleton ${className || ''}`}
    style={{
      width: width || '80px',
      height: height || '32px',
    }}
    disabled
    aria-busy='true'
    aria-label='Loading...'
  />
);

export default SkeletonButton;
