import React, { useState, useEffect, useMemo } from 'react';
import './Loading.css';

const LoadingSpinner = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fade, setFade] = useState(true);

  const reasoningSteps = useMemo(
    () => [
      { text: 'Starting reasoning...', duration: 5000 },
      { text: 'Analyzing query...', duration: 5000 },
      { text: 'Evaluating information...', duration: 10000 },
    ],
    [],
  );
  const fadeDuration = 600;

  useEffect(() => {
    const currentStep = reasoningSteps[currentIndex];
    const timeoutFadeOut = setTimeout(() => {
      setFade(false);
    }, currentStep.duration - fadeDuration);

    const timeoutSwitch = setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % reasoningSteps.length);
      setFade(true);
    }, currentStep.duration);

    return () => {
      clearTimeout(timeoutFadeOut);
      clearTimeout(timeoutSwitch);
    };
  }, [currentIndex, reasoningSteps]);

  return (
    <div>
      <div className='typing'>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div
        className='loading-message'
        style={{ opacity: fade ? 1 : 0, transition: `opacity ${fadeDuration / 1000}s ease` }}
        id='loading-text-indicaor'
      >
        {reasoningSteps[currentIndex].text}
      </div>
    </div>
  );
};

export default LoadingSpinner;
