import React, { createContext } from 'react';
import { usePdfQuestion } from '../hooks/usePdfQuestion';

export const PdfQuestionContext = createContext(null);

export const PdfQuestionProvider = ({ children }) => {
  const pdfQuestionData = usePdfQuestion();
  return (
    <PdfQuestionContext.Provider value={pdfQuestionData}>{children}</PdfQuestionContext.Provider>
  );
};
