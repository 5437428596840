import { useState } from 'react';
import { flushSync } from 'react-dom';
import PaperPreview from '../components/chat/paper_preview/PaperPreview';

export const useChatMessages = () => {
  const [messages, setMessages] = useState([]);
  const [leftoverMessages, setLeftoverMessages] = useState([]);
  const [loading, setLoading] = useState(false);

  const addMessage = (message, isUser = false) => {
    flushSync(() => {
      setMessages((messages) => [
        ...messages,
        {
          inputText: message,
          isUser,
        },
      ]);
    });
  };

  const addPaperMessages = (papers, nPapers) => {
    const filteredPapers = papers.filter((paper) => paper.content !== null).slice(0, nPapers);
    const leftoverPapers = papers.slice(nPapers);

    flushSync(() => {
      setMessages((messages) => [
        ...messages,
        ...filteredPapers.map((paper) => ({
          inputText: <PaperPreview paper={paper} />,
          isUser: false,
        })),
      ]);
      setLeftoverMessages(leftoverPapers);
    });
  };

  const showMorePapers = (nPapers) => {
    flushSync(() => {
      const newMessages = leftoverMessages.slice(0, nPapers);
      const filteredNewMessages = newMessages.filter((paper) => paper.content);

      setLeftoverMessages(leftoverMessages.slice(nPapers));
      setMessages((messages) => [
        ...messages,
        ...filteredNewMessages.map((paper) => ({
          inputText: <PaperPreview paper={paper} />,
          isUser: false,
        })),
      ]);
    });
  };

  const clearLeftoverMessages = () => {
    setLeftoverMessages([]);
  };

  const clearMessages = () => {
    setMessages([]);
    setLeftoverMessages([]);
  };

  return {
    messages,
    setMessages,
    leftoverMessages,
    setLeftoverMessages,
    loading,
    setLoading,
    addMessage,
    addPaperMessages,
    showMorePapers,
    clearMessages,
    clearLeftoverMessages,
  };
};
