import { useState, useRef, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

export const useSession = () => {
  const [sessionId, setSessionId] = useState('');
  const sessionIdRef = useRef('');

  useEffect(() => {
    if (!sessionIdRef.current) {
      const newSessionId = uuidv4();
      setSessionId(newSessionId);
      sessionIdRef.current = newSessionId;
    }
  }, []);

  const resetSession = () => {
    const newSessionId = uuidv4();
    setSessionId(newSessionId);
    sessionIdRef.current = newSessionId;
  };

  const setNewSession = (newSessionId) => {
    setSessionId(newSessionId);
    sessionIdRef.current = newSessionId;
  };

  return { sessionId, sessionIdRef, resetSession, setNewSession };
};
