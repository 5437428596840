import React from 'react';
import LoadMoreIcon from '../../icons/LoadMoreIcon';
import LoadingSpinner from '../common/loading_spinner/LoadingSpinner';

export default function MessageList({
  messages,
  leftoverMessages,
  showMorePapers,
  messagesRef,
  loading,
}) {
  return (
    <ul ref={messagesRef} className='messages'>
      {messages.map((message, index) => (
        <li
          key={index}
          className={message.isUser ? 'message right appeared' : 'message left appeared'}
        >
          <div className='text-wrapper'>
            <div className='text'>{message.inputText}</div>
          </div>
          {!message.isUser && <hr />}
        </li>
      ))}
      {loading && <LoadingSpinner message='Searching' />}

      {leftoverMessages.length > 0 && (
        <li className='message center appeared load-more-item'>
          <button className='substance-button load-more-button' onClick={showMorePapers}>
            <div className='text'>
              <span className='load-more-text'>Load more results</span>
              <LoadMoreIcon />
            </div>
          </button>
        </li>
      )}
    </ul>
  );
}
