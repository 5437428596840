import React, { createContext } from 'react';
import { useSuggestions } from '../hooks/useSuggestions';

const NUMBER_OF_SUGGESTIONS = 10;

export const SuggestionsContext = createContext(null);

export const SuggestionsProvider = ({ children }) => {
  const suggestionsData = useSuggestions(NUMBER_OF_SUGGESTIONS);
  return (
    <SuggestionsContext.Provider value={suggestionsData}>{children}</SuggestionsContext.Provider>
  );
};
