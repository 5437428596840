import React from 'react';
import { Link } from 'react-router-dom';
import './Imprint.css';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

function Imprint() {
  return (
    <div className='page-container'>
      <header className='page-header page-header-imprint'>
        <Link to='/' className='page-header-link'>
          <span className='link-blue'>Materials</span> AI Agent
          <img
            alt='Materials Science Logo'
            className='header-logo'
            src={process.env.PUBLIC_URL + '/img/chatsymbol_transparent.png'}
          />
        </Link>
      </header>
      <main>
        <div className='section' id='imprint'>
          <div className='back-to-chat-link'>
            <Link to='/' className='back-to-chat-link-link'>
              <ArrowBackIosIcon style={{ marginRight: '4px' }} />
              <span className='back-button'>Back to Chat</span>
            </Link>
          </div>
          <h1 className='page-content-headline'>
            <span className='link-blue'>Imprint</span>
          </h1>
          <div className='section-inner imprint-section'>
            <div className='imprint-container'>
              <div className='imprint-card'>
                <h3 className='imprint-subheader'>Information according to § 5 TMG:</h3>
                <p className='imprint-text'>
                  Aleksandr Perevalov
                  <br />
                  University of Paderborn
                  <br />
                  Fachgruppe Data Science (EIM-I)
                  <br />
                  Warburger Str. 100
                  <br />
                  33098 Paderborn
                  <br />
                  Deutschland
                </p>
              </div>

              <div className='imprint-card'>
                <h3 className='imprint-subheader'>Contact:</h3>
                <p>
                  Email:{' '}
                  <a href='mailto:contact@perevalov.com' className='imprint-link'>
                    contact@perevalov.com
                  </a>
                </p>
                <p>
                  Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:
                  <br />
                  <a href='https://www.uni-paderborn.de/impressum' className='imprint-link'>
                    Universität Paderborn
                  </a>
                </p>
              </div>

              <div className='imprint-card'>
                <h3 className='imprint-subheader'>Disclaimer</h3>
                <h4 className='imprint-subtitle'>Liability for Content</h4>
                <p>
                  As a service provider, we are responsible for our own content on these pages in
                  accordance with § 7 Paragraph 1 of the German Telemedia Act (TMG) under the
                  general laws. However, pursuant to §§ 8 to 10 TMG, we as a service provider are
                  not obligated to monitor transmitted or stored third-party information or to
                  investigate circumstances that indicate illegal activity. Obligations to remove or
                  block the use of information under the general laws remain unaffected. Liability
                  in this regard is only possible from the time of knowledge of a specific legal
                  infringement. Upon becoming aware of corresponding legal violations, we will
                  remove such content immediately.
                </p>
              </div>

              <div className='imprint-card'>
                <h4 className='imprint-subtitle'>Liability for Links</h4>
                <p>
                  Our offer contains links to external websites of third parties, over whose content
                  we have no influence. Therefore, we cannot assume any liability for this
                  third-party content. The respective provider or operator of the pages is always
                  responsible for the content of the linked pages. The linked pages were checked for
                  possible legal violations at the time of linking. Illegal content was not
                  recognizable at the time of linking. However, permanent content control of the
                  linked pages is not reasonable without concrete evidence of a legal violation.
                  Upon becoming aware of legal violations, we will remove such links immediately.
                </p>
              </div>

              <div className='imprint-card'>
                <h4 className='imprint-subtitle'>Copyright</h4>
                <p>
                  The content and works created by the site operators on these pages are subject to
                  German copyright law. Reproduction, editing, distribution, and any kind of
                  utilization beyond the limits of copyright require the written consent of the
                  respective author or creator. Downloads and copies of this site are permitted only
                  for private, non-commercial use. Insofar as the content on this site was not
                  created by the operator, the copyrights of third parties are respected. In
                  particular, third-party content is marked as such. Should you nevertheless become
                  aware of a copyright infringement, please let us know accordingly. Upon becoming
                  aware of legal violations, we will remove such content immediately.
                </p>
              </div>

              <div className='imprint-card'>
                <h3 className='imprint-subheader'>Privacy</h3>
                <p>
                  See our privacy policy{' '}
                  <Link to='/privacy' className='imprint-link'>
                    here
                  </Link>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
      <footer className='page-footer'>
        <div className='page-footer-content'>
          <div className='footer-links'>
            <Link to='/'>Chat</Link>
            <Link to='/about'>About</Link>
            <Link to='/privacy'>Privacy</Link>
          </div>
          <div className='footer-copyright'>
            <span className='footer-copyright-icon'>©</span>
            {new Date().getFullYear()} Materials AI Agent. All rights reserved.
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Imprint;
