import React from 'react';

const LoadMoreIcon = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M6 9l6 6 6-6'
      stroke='currentColor'
      strokeWidth='2'
      fill='none'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default LoadMoreIcon;
