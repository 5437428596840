import React from 'react';

export const copyToClipboard = (bibtext) => {
  navigator.clipboard
    .writeText(bibtext)
    .then(() => {
      console.log('Text copied to clipboard');
    })
    .catch((err) => {
      console.error('Could not copy text: ', err);
    });
};

// New helper that serializes AgentAnswer React element into plain data.
export const serializeAgentAnswer = (element) => {
  const { answer, substanceProperty } = element.props;
  return { answer, substanceProperty, papers: [] };
};

// New helper that serializes PaperPreview React element into plain data.
export const serializePaperPreview = (element) => {
  const { paper } = element.props;
  return paper;
};

export const serializeMessage = (m) => {
  if (
    React.isValidElement(m.inputText) &&
    m.inputText.type &&
    (m.inputText.type.displayName === 'AgentAnswer' || m.inputText.type.name === 'AgentAnswer')
  ) {
    return { inputText: serializeAgentAnswer(m.inputText), isUser: m.isUser };
  }

  if (
    React.isValidElement(m.inputText) &&
    m.inputText.type &&
    m.inputText.type.name === 'PaperPreview'
  ) {
    return { inputText: serializePaperPreview(m.inputText), isUser: m.isUser };
  }

  // If inputText is a non-empty string, return as is.
  if (typeof m.inputText === 'string' && m.inputText.trim()) {
    return { inputText: m.inputText, isUser: m.isUser };
  }
  // If inputText is already a plain object with an "answer" property, return it.
  if (
    m.inputText &&
    typeof m.inputText === 'object' &&
    !m.inputText.$$typeof &&
    m.inputText.answer
  ) {
    return { inputText: m.inputText, isUser: m.isUser };
  }
  return null;
};

export const truncateTitle = (title, maxLength = 50) => {
  if (title.length <= maxLength) return title;

  if (title[maxLength] === ' ') {
    return title.slice(0, maxLength).trim() + '...';
  }

  const nextSpace = title.indexOf(' ', maxLength);
  if (nextSpace !== -1) {
    return title.slice(0, nextSpace).trim() + '...';
  }

  return title;
};
