import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

export const useUserId = () => {
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    // Function to check and set userId based on cookie consent
    const checkAndSetUserId = () => {
      // Check if user already has an ID
      let storedId = localStorage.getItem('classmate_user_id');

      // Get consent status from localStorage
      const consentMode = localStorage.getItem('consentMode');
      const hasConsent = consentMode && JSON.parse(consentMode).functionality_storage === 'granted';

      // If we have consent and no existing ID, create one
      if (hasConsent && !storedId) {
        storedId = uuidv4();
        localStorage.setItem('classmate_user_id', storedId);
      }

      // Set ID in state if it exists
      if (storedId) {
        setUserId(storedId);
      }
    };

    // Initial check
    checkAndSetUserId();

    // Set up event listener for consent changes
    const handleConsentChange = () => {
      checkAndSetUserId();
    };

    window.addEventListener('consentUpdated', handleConsentChange);

    // Clean up event listener
    return () => {
      window.removeEventListener('consentUpdated', handleConsentChange);
    };
  }, []);

  return userId;
};
