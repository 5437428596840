import React from 'react';
import { Link } from 'react-router-dom';
import './Error.css';
import DescriptionIcon from '@mui/icons-material/Description';

function Error() {
  return (
    <div className='page-container'>
      <header className='page-header'>
        <Link to='/' className='page-header-link'>
          <span className='link-blue'>Materials</span> AI Agent
          <img
            alt='Materials Science Logo'
            className='header-logo'
            src={process.env.PUBLIC_URL + '/img/chatsymbol_transparent.png'}
          />
        </Link>
      </header>
      <main className='error-main'>
        <div className='error-content'>
          <h1 className='page-content-headline-error'>Oops! Page Not Found</h1>
          <p className='error-description'>
            The page you&apos;re looking for doesn&apos;t exist or has been moved. Don&apos;t worry,
            you can easily navigate back to our main chat interface where you can continue your
            materials science research.
          </p>
          <Link to='/' className='error-back-button'>
            <DescriptionIcon style={{ marginRight: '8px', verticalAlign: 'middle' }} />
            Return to Chat
          </Link>
        </div>
      </main>
      <footer className='page-footer'>
        <div className='page-footer-content'>
          <div className='footer-links'>
            <Link to='/'>Chat</Link>
            <Link to='/about'>Chat</Link>
            <Link to='/imprint'>Imprint</Link>
            <Link to='/privacy'>Privacy</Link>
          </div>
          <div className='footer-copyright'>
            <span className='footer-copyright-icon'>©</span>
            {new Date().getFullYear()} Materials AI Agent. All rights reserved.
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Error;
