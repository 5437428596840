import React from 'react';

const DefaultPerson = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='100'
    height='100'
    viewBox='0 0 100 100'
    className='team-photo'
  >
    <circle cx='50' cy='50' r='50' fill='#ccc' />
    <circle cx='50' cy='30' r='15' fill='#999' />
    <path d='M 20,80 C 20,65 35,55 50,55 C 65,55 80,65 80,80 Z' fill='#999' />
  </svg>
);

export default DefaultPerson;
