import React, { useState, useRef } from 'react';
import { useMediaQuery } from '@mui/material';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { BookmarksProvider } from './contexts/BookmarksContext';
import { DialoguesProvider } from './contexts/DialoguesContext';
import { ChatMessagesProvider } from './contexts/MessagesContext';
import { SessionProvider } from './contexts/SessionContext';
import { SuggestionsProvider } from './contexts/SuggestionsContext';
import { PdfQuestionProvider } from './contexts/PdfQuestionContext';
import { ConsentProvider } from './contexts/ConsentContext';
import { useUserId } from './hooks/useUserId';
import Sidebar from './components/layout/sidebar/Sidebar';
import ChatWindow from './components/chat/ChatWindow';
import About from './components/pages/about/About';
import Imprint from './components/pages/imprint/Imprint';
import Privacy from './components/pages/privacy/Privacy';
import Error from './components/pages/error/Error';
import Cookies from './components/cookies/Cookies';

import './assets/css/Chat.css';
import './assets/css/Navigation.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  useUserId();
  const isDesktop = useMediaQuery('(min-width:768px)');
  const [sidebarExpanded, setSidebarExpanded] = useState(true);
  const [dialogueId, setDialogueId] = useState(null);

  const abortControllerRef = useRef(null);
  const cancelRequest = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
  };

  const Home = () => (
    <div className='app-container'>
      <Sidebar
        expanded={sidebarExpanded}
        setExpanded={setSidebarExpanded}
        setDialogueId={setDialogueId}
        cancelRequest={cancelRequest}
      />
      <div
        className='chat-window-container'
        style={{
          marginLeft: isDesktop ? (sidebarExpanded ? '250px' : '60px') : 0,
          transition: 'margin-left 0.3s ease',
        }}
      >
        <ChatWindow
          dialogueId={dialogueId}
          setDialogueId={setDialogueId}
          abortControllerRef={abortControllerRef}
        />
      </div>
    </div>
  );

  return (
    <BrowserRouter>
      <ConsentProvider>
        <SessionProvider>
          <BookmarksProvider>
            <DialoguesProvider>
              <ChatMessagesProvider>
                <SuggestionsProvider>
                  <PdfQuestionProvider>
                    <Routes>
                      <Route path='/' element={<Home />} />
                      <Route path='/about' element={<About />} />
                      <Route path='/imprint' element={<Imprint />} />
                      <Route path='/privacy' element={<Privacy />} />
                      <Route path='*' element={<Error />} />
                    </Routes>
                    <Cookies />
                  </PdfQuestionProvider>
                </SuggestionsProvider>
              </ChatMessagesProvider>
            </DialoguesProvider>
          </BookmarksProvider>
        </SessionProvider>
      </ConsentProvider>
    </BrowserRouter>
  );
}

export default App;
