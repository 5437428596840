import { useState, useMemo, useEffect } from 'react';
import { debounce } from 'lodash';
import { fetchAutoComplete } from '../services/api';

export const useAutoComplete = (inputText, sessionId) => {
  const [completions, setCompletions] = useState([]);
  const [currentCompletion, setCurrentCompletion] = useState('');

  const getAutoCompletions = async (input) => {
    if (input?.length > 0 && sessionId) {
      const completion = await fetchAutoComplete(input, sessionId);
      if (completion && completion.toLowerCase().startsWith(input.toLowerCase())) {
        setCurrentCompletion(completion);
        setCompletions([
          {
            text: completion,
            value: completion,
            highlightedValue: input,
          },
        ]);
      } else {
        setCurrentCompletion('');
        setCompletions([]);
      }
    } else {
      setCurrentCompletion('');
      setCompletions([]);
    }
  };

  const debouncedFetch = useMemo(() => debounce(getAutoCompletions, 100), [sessionId]);

  useEffect(() => {
    if (inputText) {
      debouncedFetch(inputText);
    } else {
      setCompletions([]);
      setCurrentCompletion('');
    }
    return () => debouncedFetch.cancel();
  }, [inputText, debouncedFetch]);

  return { completions, currentCompletion, setCompletions };
};
