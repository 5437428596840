import React, { createContext, useState, useEffect, useContext } from 'react';

// Helper to check consent from localStorage
const checkConsentFromStorage = () => {
  try {
    const consentMode = localStorage.getItem('consentMode');
    if (consentMode) {
      const parsedConsent = JSON.parse(consentMode);
      return parsedConsent.functionality_storage === 'granted';
    }
  } catch (e) {
    console.error('Error checking consent from localStorage:', e);
  }
  return false;
};

// Create a default context value
const defaultContextValue = {
  hasNecessaryConsent: checkConsentFromStorage(),
  showConsentBanner: false,
  setShowConsentBanner: () => {},
  openConsentBanner: () => {},
};

export const ConsentContext = createContext(defaultContextValue);

export const ConsentProvider = ({ children }) => {
  // Initialize state from localStorage
  const [hasNecessaryConsent, setHasNecessaryConsent] = useState(checkConsentFromStorage());
  const [showConsentBanner, setShowConsentBanner] = useState(false);

  useEffect(() => {
    // Check consent status on initial load
    const checkConsent = () => {
      const consentMode = localStorage.getItem('consentMode');
      if (consentMode) {
        const parsedConsent = JSON.parse(consentMode);
        setHasNecessaryConsent(parsedConsent.functionality_storage === 'granted');
      } else {
        setHasNecessaryConsent(false);
      }
    };

    checkConsent();

    // Listen for consent updates
    const handleConsentUpdate = () => {
      checkConsent();
    };

    window.addEventListener('consentUpdated', handleConsentUpdate);
    return () => window.removeEventListener('consentUpdated', handleConsentUpdate);
  }, []);

  const openConsentBanner = () => {
    setShowConsentBanner(true);
  };

  return (
    <ConsentContext.Provider
      value={{
        hasNecessaryConsent,
        showConsentBanner,
        setShowConsentBanner,
        openConsentBanner,
      }}
    >
      {children}
    </ConsentContext.Provider>
  );
};

export const useConsent = () => useContext(ConsentContext);
