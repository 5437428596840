import React from 'react';
import { Link } from 'react-router-dom';
import './Privacy.css';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

function Privacy() {
  return (
    <div className='page-container'>
      <header className='page-header page-header-privacy'>
        <Link to='/' className='page-header-link'>
          <span className='link-blue'>Materials</span> AI Agent
          <img
            alt='Materials Science Logo'
            className='header-logo'
            src={process.env.PUBLIC_URL + '/img/chatsymbol_transparent.png'}
          />
        </Link>
      </header>
      <main>
        <div className='section' id='privacy'>
          <div className='back-to-chat-link'>
            <Link to='/' className='back-to-chat-link-link'>
              <ArrowBackIosIcon style={{ marginRight: '4px' }} />
              <span className='back-button'>Back to Chat</span>
            </Link>
          </div>
          <h1 className='page-content-headline'>
            <span className='link-blue'>Privacy Policy</span>
          </h1>
          <div className='section-inner privacy-section'>
            <div className='privacy-container'>
              <div className='privacy-card'>
                <p>
                  This website, as the data controller for processing, has implemented numerous
                  technical and organizational measures to ensure the most complete possible
                  protection of the personal data processed through it. However, internet-based data
                  transmissions may in principle exhibit security vulnerabilities, so absolute
                  protection cannot be guaranteed. For this reason, every affected person is free to
                  transmit personal data by alternative means, for example by telephone.
                </p>
              </div>

              {/* Section 1 */}
              <div className='privacy-card'>
                <h3 className='privacy-subheader'>1. Definitions</h3>
                <p>
                  The Privacy Policy of this website is based on the terminology used by the
                  European legislator in the adoption of the General Data Protection Regulation
                  (GDPR). Our privacy policy is intended to be easy to read and understand for both
                  the public as well as our customers and business partners. In order to ensure
                  this, we would like to first explain the terms used.
                </p>
                <p>In this privacy policy we use, among other things, the following terms:</p>

                <h4 className='privacy-subtitle'>Personal Data</h4>
                <p>
                  Personal data means any information relating to an identified or identifiable
                  natural person (hereinafter &quot;data subject&quot;). A natural person is
                  considered identifiable if he or she can be identified, directly or indirectly, in
                  particular by reference to an identifier such as a name, an identification number,
                  location data, an online identifier or one or more factors specific to the
                  physical, physiological, genetic, mental, economic, cultural or social identity of
                  that natural person.
                </p>

                <h4 className='privacy-subtitle'>Data Subject</h4>
                <p>
                  A data subject is any identified or identifiable natural person whose personal
                  data is processed by the data controller.
                </p>

                <h4 className='privacy-subtitle'>Processing</h4>
                <p>
                  Processing means any operation or set of operations which is performed on personal
                  data or on sets of personal data, whether or not by automated means, such as
                  collection, recording, organization, structuring, storage, adaptation or
                  alteration, retrieval, consultation, use, disclosure by transmission,
                  dissemination or otherwise making available, alignment or combination,
                  restriction, erasure or destruction.
                </p>

                <h4 className='privacy-subtitle'>Restriction of Processing</h4>
                <p>
                  Restriction of processing means marking stored personal data with the aim of
                  limiting their future processing.
                </p>

                <h4 className='privacy-subtitle'>Profiling</h4>
                <p>
                  Profiling is any form of automated processing of personal data consisting of the
                  use of personal data to evaluate certain personal aspects relating to a natural
                  person, in particular to analyze or predict aspects concerning that natural
                  person&apos;s performance at work, economic situation, health, personal
                  preferences, interests, reliability, behavior, location or movements.
                </p>

                <h4 className='privacy-subtitle'>Pseudonymisation</h4>
                <p>
                  Pseudonymisation means the processing of personal data in such a manner that the
                  personal data can no longer be attributed to a specific data subject without the
                  use of additional information, provided that such additional information is kept
                  separately and is subject to technical and organizational measures to ensure that
                  the personal data are not attributed to an identified or identifiable natural
                  person.
                </p>

                <h4 className='privacy-subtitle'>Controller or Data Controller</h4>
                <p>
                  The controller or data controller is the natural or legal person, public
                  authority, agency or other body which, alone or jointly with others, determines
                  the purposes and means of the processing of personal data. If the purposes and
                  means of such processing are provided for by Union law or the law of the Member
                  States, the controller or the criteria for its nomination may be provided for by
                  Union law or the law of the Member States.
                </p>

                <h4 className='privacy-subtitle'>Processor</h4>
                <p>
                  A processor is a natural or legal person, public authority, agency or other body
                  which processes personal data on behalf of the controller.
                </p>

                <h4 className='privacy-subtitle'>Recipient</h4>
                <p>
                  A recipient is a natural or legal person, public authority, agency or another
                  body, to which the personal data are disclosed, irrespective of whether it is a
                  third party. However, public authorities which may receive personal data in the
                  framework of a specific inquiry in accordance with Union law or the law of the
                  Member States are not regarded as recipients.
                </p>

                <h4 className='privacy-subtitle'>Third Party</h4>
                <p>
                  A third party is a natural or legal person, public authority, agency or another
                  body other than the data subject, controller, processor, and the persons who,
                  under the direct authority of the controller or processor, are authorized to
                  process the personal data.
                </p>

                <h4 className='privacy-subtitle'>Consent</h4>
                <p>
                  Consent is any freely given, specific, informed and unambiguous indication of the
                  data subject&apos;s wishes by which he or she, by a statement or by a clear
                  affirmative action, signifies agreement to the processing of personal data
                  relating to him or her.
                </p>
              </div>

              {/* Section 2 */}
              <div className='privacy-card'>
                <h3 className='privacy-subheader'>2. Name and Address of the Data Controller</h3>
                <p>
                  The data controller within the meaning of the GDPR, other data protection laws
                  applicable in the Member States of the European Union, and other data protection
                  regulations is:
                </p>
                <p>
                  Aleksandr Perevalov
                  <br />
                  University of Paderborn
                  <br />
                  Fachgruppe Data Science (EIM-I)
                  <br />
                  Warburger Str. 100
                  <br />
                  33098 Paderborn
                  <br />
                  Deutschland
                  <br />
                  Email:{' '}
                  <a href='mailto:contact@perevalov.com' className='privacy-link'>
                    contact@perevalov.com
                  </a>
                </p>
              </div>

              {/* Section 3 */}
              <div className='privacy-card'>
                <h3 className='privacy-subheader'>3. Cookies</h3>
                <p>
                  This website uses cookies. Cookies are text files which are stored on a computer
                  system via an internet browser.
                </p>
                <p>
                  Numerous websites and servers use cookies. Many cookies contain a so-called cookie
                  ID, which is a unique identifier. This unique identifier is a string that enables
                  websites and servers to assign a particular internet browser in which the cookie
                  is stored. This allows the visited websites and servers to distinguish the
                  individual browser of the data subject from other browsers containing other
                  cookies. A specific browser can be recognized and identified by its unique cookie
                  ID.
                </p>
                <p>
                  By using cookies, this website can provide more user-friendly services that would
                  not be possible without cookie usage.
                </p>
                <p>
                  Through a cookie, the information and services on this website can be optimized in
                  the interest of the user. As already mentioned, cookies enable us to recognize the
                  users of this website. The purpose of this recognition is to facilitate the use of
                  this website for users. For example, a user of a website using cookies does not
                  have to re-enter login details on every visit, because the website takes over
                  these details from the cookie stored on the users computer. Another example is the
                  cookie for an online shop&apos;s shopping cart. The online shop remembers the
                  items a customer has placed in the virtual shopping cart via a cookie.
                </p>
                <p>
                  The data subject can prevent the setting of cookies on this website at any time by
                  means of a corresponding setting in the internet browser used and thereby
                  permanently object to the setting of cookies. Furthermore, cookies already set can
                  be deleted at any time via an internet browser or other software programs. This is
                  possible in all common internet browsers. If the data subject disables cookies in
                  the internet browser used, some functions of this website may not be fully
                  available.
                </p>
              </div>

              {/* Section 4 */}
              <div className='privacy-card'>
                <h3 className='privacy-subheader'>4. Collection of General Data and Information</h3>
                <p>
                  This website collects a series of general data and information with each access by
                  a data subject or an automated system. These general data and information are
                  stored in the server log files. The data that may be recorded include: (1) the
                  types and versions of browsers used, (2) the operating system used by the
                  accessing system, (3) the website from which an accessing system reached this
                  website (so-called referrers), (4) the sub-pages which are accessed via an
                  accessing system on this website, (5) the date and time of access, (6) an internet
                  protocol address (IP address), (7) the internet service provider of the accessing
                  system, and (8) other similar data and information that serve the prevention of
                  danger in the event of attacks on our IT systems.
                </p>
                <p>
                  In using these general data and information, this website does not draw any
                  conclusions about the data subject. These data are needed to (1) correctly deliver
                  the content of this website, (2) optimize the content of this website and its
                  advertising, (3) ensure the ongoing functionality of our IT systems and the
                  technology of this website, and (4) provide law enforcement authorities with the
                  information necessary for prosecution in the event of a cyber attack. These
                  anonymously collected data are evaluated statistically and with the aim of
                  increasing data protection and data security in our company in order ultimately to
                  ensure an optimal level of protection for the personal data we process. The
                  anonymous data from the server log files are stored separately from any personal
                  data provided by a data subject.
                </p>
              </div>

              {/* Section 5 */}
              <div className='privacy-card'>
                <h3 className='privacy-subheader'>5. Contact Options via the Website</h3>
                <p>
                  In accordance with legal regulations, this website contains information that
                  enables quick electronic contact with our company as well as direct communication,
                  which also includes a general email address. If a data subject contacts the data
                  controller by e-mail or via a contact form, the personal data transmitted by the
                  data subject are automatically stored. Such personal data, voluntarily provided by
                  a data subject to the data controller, are stored for the purpose of processing or
                  contacting the data subject. There is no transfer of these personal data to third
                  parties.
                </p>
              </div>

              {/* Section 6 */}
              <div className='privacy-card'>
                <h3 className='privacy-subheader'>
                  6. Routine Deletion and Blocking of Personal Data
                </h3>
                <p>
                  The data controller processes and stores personal data of the data subject only
                  for the period necessary to achieve the purpose of storage or as provided by the
                  European legislator or other applicable laws. If the purpose of storage ceases or
                  a legally prescribed retention period expires, the personal data are routinely
                  blocked or deleted in accordance with statutory provisions.
                </p>
              </div>

              {/* Section 7 */}
              <div className='privacy-card'>
                <h3 className='privacy-subheader'>7. Rights of the Data Subject</h3>
                <h4 className='privacy-subtitle'>Right to Confirmation</h4>
                <p>
                  Every data subject has the right granted by the European legislator to obtain from
                  the data controller confirmation as to whether personal data concerning him or her
                  are being processed. If a data subject wishes to exercise this right, he or she
                  may contact an employee of the data controller at any time.
                </p>
                <h4 className='privacy-subtitle'>Right to Information</h4>
                <p>
                  Every data subject whose personal data are processed has the right to receive free
                  information from the data controller regarding the personal data stored about him
                  or her and a copy of that information. Furthermore, the data subject is entitled
                  to obtain information about the following:
                </p>
                <ul>
                  <li>The purposes of the processing</li>
                  <li>The categories of personal data processed</li>
                  <li>
                    The recipients or categories of recipients to whom the personal data have been
                    or will be disclosed, particularly if they are in third countries or
                    international organizations
                  </li>
                  <li>
                    If possible, the planned duration for which the personal data will be stored, or
                    the criteria used to determine that period
                  </li>
                  <li>
                    The existence of a right to rectification or erasure of the personal data or
                    restriction of processing, or a right to object to such processing
                  </li>
                  <li>
                    The existence of a right to lodge a complaint with a supervisory authority
                  </li>
                  <li>
                    If the personal data were not collected from the data subject, all available
                    information as to the source of the data
                  </li>
                  <li>
                    The existence of automated decision-making including profiling and meaningful
                    information about the logic involved as well as the significance and envisaged
                    consequences of such processing for the data subject
                  </li>
                </ul>
                <h4 className='privacy-subtitle'>Right to Rectification</h4>
                <p>
                  Every data subject whose personal data are processed has the right to obtain the
                  prompt rectification of inaccurate personal data concerning him or her.
                  Furthermore, the data subject is entitled, considering the purposes of the
                  processing, to have incomplete personal data completed – including by means of a
                  supplementary statement.
                </p>
                <h4 className='privacy-subtitle'>Right to Erasure (Right to be Forgotten)</h4>
                <p>
                  Every data subject whose personal data are processed has the right to request the
                  erasure of personal data concerning him or her without undue delay, provided that
                  one of the following conditions applies and the processing is not necessary:
                </p>
                <ul>
                  <li>
                    The personal data were collected for purposes for which they are no longer
                    necessary.
                  </li>
                  <li>
                    The data subject withdraws his or her consent on which the processing is based
                    and no other legal basis for the processing exists.
                  </li>
                  <li>
                    The data subject objects to the processing and there are no overriding
                    legitimate grounds for the processing.
                  </li>
                  <li>The personal data have been processed unlawfully.</li>
                  <li>
                    The erasure of the personal data is necessary for compliance with a legal
                    obligation.
                  </li>
                  <li>
                    The personal data were collected in relation to the offer of information society
                    services.
                  </li>
                </ul>
                <p>
                  If one of the above conditions applies and a data subject wishes to have the
                  personal data stored by this website erased, he or she may contact an employee of
                  the data controller at any time. The employee will ensure that the deletion
                  request is carried out promptly. If the personal data have been made public and
                  the data controller is obliged to erase them, appropriate measures will be taken
                  to inform other controllers processing the data that the data subject has
                  requested deletion.
                </p>
                <h4 className='privacy-subtitle'>Right to Restriction of Processing</h4>
                <p>
                  Every data subject whose personal data are processed has the right to request the
                  restriction of processing if one of the following conditions applies:
                </p>
                <ul>
                  <li>
                    The accuracy of the personal data is contested by the data subject for a period
                    enabling the controller to verify their accuracy.
                  </li>
                  <li>
                    The processing is unlawful and the data subject opposes the erasure of the
                    personal data and requests instead the restriction of their use.
                  </li>
                  <li>
                    The controller no longer needs the personal data for the purposes of processing,
                    but the data subject requires them for the establishment, exercise, or defense
                    of legal claims.
                  </li>
                  <li>
                    The data subject has objected to the processing and it has not yet been
                    determined whether the legitimate grounds of the controller override those of
                    the data subject.
                  </li>
                </ul>
                <h4 className='privacy-subtitle'>Right to Data Portability</h4>
                <p>
                  Every data subject whose personal data are processed has the right to receive the
                  personal data concerning him or her in a structured, commonly used, and
                  machine-readable format, and the right to transmit those data to another
                  controller, provided that the processing is based on consent or a contract and is
                  carried out by automated means. Furthermore, the data subject has the right to
                  have the personal data transmitted directly from one controller to another,
                  insofar as this is technically feasible and does not affect the rights and
                  freedoms of others.
                </p>
                <h4 className='privacy-subtitle'>Right to Object</h4>
                <p>
                  Every data subject whose personal data are processed has the right to object, on
                  grounds related to his or her particular situation, to the processing of personal
                  data concerning him or her that is based on public interest or legitimate
                  interests, including profiling. In such cases, this website will cease processing
                  the personal data unless compelling legitimate grounds for processing are
                  demonstrated.
                </p>
                <p>
                  If personal data are processed for direct marketing purposes, the data subject may
                  object at any time to the processing for such purposes. This also applies to
                  profiling related to direct marketing. An objection in this context will result in
                  the cessation of processing for these purposes.
                </p>
                <h4 className='privacy-subtitle'>
                  Automated Individual Decision-Making Including Profiling
                </h4>
                <p>
                  Every data subject whose personal data are processed has the right not to be
                  subject to a decision based solely on automated processing – including profiling –
                  which produces legal effects concerning him or her or similarly significantly
                  affects him or her, unless the decision is necessary for entering into or
                  performing a contract, is authorized by law with appropriate safeguards, or is
                  based on the data subject&apos;s explicit consent. In such cases, appropriate
                  measures will be taken to safeguard the data subject&apos;s rights, including the
                  right to obtain human intervention, to express his or her point of view, and to
                  contest the decision.
                </p>
                <h4 className='privacy-subtitle'>Right to Withdraw a Data Protection Consent</h4>
                <p>
                  Every data subject whose personal data are processed has the right to withdraw
                  consent at any time. If the data subject wishes to exercise this right, he or she
                  may contact an employee of the data controller.
                </p>
              </div>

              {/* Section 8 */}
              <div className='privacy-card'>
                <h3 className='privacy-subheader'>
                  8. Data Protection in Applications and Application Procedures
                </h3>
                <p>
                  The data controller collects and processes the personal data of applicants for the
                  purpose of handling application procedures. Processing may also be carried out
                  electronically, for example if an applicant submits application documents via
                  e-mail or a web form. If an employment contract is concluded with an applicant,
                  the submitted data will be stored for managing the employment relationship in
                  compliance with legal provisions. If no employment contract is concluded, the
                  application documents will be automatically deleted two months after the rejection
                  has been communicated, unless other legitimate interests prevail.
                </p>
              </div>

              {/* Section 9 */}
              <div className='privacy-card'>
                <h3 className='privacy-subheader'>
                  9. Data Protection Provisions for the Use of Google Analytics (with Anonymisation
                  Function)
                </h3>
                <p>
                  On this website, the data controller has integrated the Google Analytics component
                  (with an anonymisation function). Google Analytics is a web analytics service that
                  collects data on visitor behavior – such as referrers, accessed sub-pages, and
                  duration of visits – to optimize this website and evaluate online advertising.
                </p>
                <p>
                  The operating company of the Google Analytics component is Google Inc., 1600
                  Amphitheatre Pkwy, Mountain View, CA 94043-1351, USA.
                </p>
                <p>
                  The data controller uses the &quot;_gat._anonymizeIp&quot; parameter so that the
                  data subject&apos;s IP address is shortened and anonymized when this website is
                  accessed from a Member State of the EU or another contracting state of the EEA.
                </p>
                <p>
                  The purpose of the Google Analytics component is to analyze visitor flows on this
                  website. Google uses the collected data to evaluate website usage, create reports,
                  and provide related services. A cookie is set on the data subject&apos;s IT
                  system, storing personal data such as access times, location, and frequency of
                  visits. These data, including the IP address, are transmitted to and stored by
                  Google in the USA, where they may be shared with third parties.
                </p>
                <p>
                  The data subject can prevent the setting of cookies via browser settings, which
                  would also prevent Google Analytics from setting its cookie. Additionally, cookies
                  set by Google Analytics can be deleted at any time.
                </p>
                <p>
                  Furthermore, the data subject may object to the collection and processing of data
                  generated by Google Analytics by installing the browser add-on available at{' '}
                  <a
                    href='https://tools.google.com/dlpage/gaoptout'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='privacy-link'
                  >
                    https://tools.google.com/dlpage/gaoptout
                  </a>
                  .
                </p>
              </div>

              {/* Section 10 */}
              <div className='privacy-card'>
                <h3 className='privacy-subheader'>10. Legal Basis for the Processing</h3>
                <p>
                  Article 6(1)(a) of the GDPR serves as our legal basis for processing operations
                  for which we obtain consent for a specific processing purpose. If the processing
                  of personal data is necessary for the performance of a contract to which the data
                  subject is a party (for example, processing necessary for the delivery of goods or
                  the provision of services), the processing is based on Article 6(1)(b) of the
                  GDPR. The same applies to processing necessary for pre-contractual measures, such
                  as inquiries about our products or services. If our company is subject to a legal
                  obligation that requires the processing of personal data (e.g. for tax purposes),
                  the processing is based on Article 6(1)(c) of the GDPR. In rare cases, processing
                  may be necessary to protect the vital interests of the data subject or another
                  natural person (for example, in the event of an injury on our premises). In such
                  cases, the processing would be based on Article 6(1)(d) of the GDPR. Finally,
                  processing operations could be based on Article 6(1)(f) of the GDPR if they are
                  necessary for the purposes of the legitimate interests pursued by our company or a
                  third party, provided that these interests do not override the interests, rights,
                  and freedoms of the data subject.
                </p>
              </div>

              {/* Section 11 */}
              <div className='privacy-card'>
                <h3 className='privacy-subheader'>
                  11. Legitimate Interests Pursued by the Controller or a Third Party
                </h3>
                <p>
                  If the processing of personal data is based on Article 6(1)(f) of the GDPR, our
                  legitimate interest is the conduct of our business operations for the benefit of
                  all our employees and our shareholders.
                </p>
              </div>

              {/* Section 12 */}
              <div className='privacy-card'>
                <h3 className='privacy-subheader'>
                  12. Duration for Which the Personal Data are Stored
                </h3>
                <p>
                  The duration of storage of personal data is determined by the respective legal
                  retention period. After the expiration of the retention period, the corresponding
                  data are routinely deleted, provided that they are no longer necessary for the
                  performance or initiation of a contract.
                </p>
              </div>

              {/* Section 13 */}
              <div className='privacy-card'>
                <h3 className='privacy-subheader'>
                  13. Legal or Contractual Provisions for the Provision of Personal Data; Necessity
                  for Contract Conclusion; Obligation of the Data Subject; Possible Consequences of
                  Non-Disclosure
                </h3>
                <p>
                  We inform you that the provision of personal data is partly legally prescribed
                  (e.g., tax regulations) or may result from contractual provisions (e.g.,
                  information about the contractual partner). In some cases, it may be necessary for
                  a contract to be concluded that a data subject provides personal data which must
                  subsequently be processed. For example, if our company enters into a contract with
                  a data subject, the provision of personal data is required. Failure to provide
                  these personal data would result in the contract not being concluded. Before
                  providing personal data, the data subject must contact one of our employees, who
                  will clarify on a case-by-case basis whether the provision is legally or
                  contractually required, whether there is an obligation to provide the data, and
                  what the consequences of non-disclosure would be.
                </p>
              </div>

              {/* Section 14 */}
              <div className='privacy-card'>
                <h3 className='privacy-subheader'>14. Use of Web Fonts</h3>
                <p>
                  External fonts, specifically Google Fonts, are used on these websites. Google
                  Fonts is a service provided by Google Inc. (&quot;Google&quot;). The integration
                  of these web fonts takes place via a server call, usually to a Google server in
                  the USA. This call transmits information about which of our websites you have
                  visited, and the IP address of the visitor&apos;s browser is stored by Google. For
                  further details, please refer to Google&apos;s privacy notice at:{' '}
                  <a
                    href='https://www.google.com/fonts#AboutPlace:about'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='privacy-link'
                  >
                    www.google.com/fonts#AboutPlace:about
                  </a>{' '}
                  and{' '}
                  <a
                    href='https://www.google.com/policies/privacy/'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='privacy-link'
                  >
                    www.google.com/policies/privacy/
                  </a>
                  .
                </p>
              </div>

              {/* Section 15 */}
              <div className='privacy-card'>
                <h3 className='privacy-subheader'>15. Existence of Automated Decision-Making</h3>
                <p>
                  As a responsible company, we refrain from automated decision-making or profiling.
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
      <footer className='page-footer'>
        <div className='page-footer-content'>
          <div className='footer-links'>
            <Link to='/'>Chat</Link>
            <Link to='/about'>About</Link>
            <Link to='/imprint'>Imprint</Link>
          </div>
          <div className='footer-copyright'>
            <span className='footer-copyright-icon'>©</span>
            {new Date().getFullYear()} Materials AI Agent. All rights reserved.
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Privacy;
