import React, { useState } from 'react';
import BookmarkIcon from '../../../icons/BookmarkIcon';
import RemoveBookmarkIcon from '../../../icons/RemoveBookmarkIcon';
import CiteIcon from '../../../icons/CiteIcon';
import PaperModal from '../../modals/PaperModal';
import { copyToClipboard } from '../../../services/utils';
import Separator from '../../common/separator/Separator';
import useToggleBookmarks from '../../../hooks/useToggleBookmarks';
import useIsMobile from '../../../hooks/useIsMobile';

const DEFAULT_ABSTRACT_LEN = process.env.REACT_APP_SNIPPET_LENGTH || 500;

const PaperPreview = ({ paper }) => {
  const isMobile = useIsMobile();
  const ABSTRACT_LEN = isMobile ? 250 : DEFAULT_ABSTRACT_LEN;
  const [showPaperModal, setShowPaperModal] = useState(false);
  const [selectedPaper, setSelectedPaper] = useState(null);
  const { isBookmarked, handleToggleBookmark } = useToggleBookmarks(paper);

  const handlePaperClick = (paperData) => {
    setSelectedPaper(paperData);
    setShowPaperModal(true);
  };

  const pdfLink =
    paper.pdfUrl !== 'none' ? (
      <>
        <Separator />
        <a target='_blank' rel='noopener noreferrer' href={paper.pdfUrl}>
          PDF
        </a>
      </>
    ) : null;

  return (
    <div>
      <div className='paper-preview-modal-container' onClick={() => handlePaperClick(paper)}>
        <div className='paper-title'>{paper.title}</div>
        <div className='snippet'>
          <span>
            {paper.content?.length > ABSTRACT_LEN
              ? paper.content.substring(0, ABSTRACT_LEN) + ' ...'
              : paper.content}
          </span>
        </div>
      </div>
      <br />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ width: '60%' }}>
          {paper.displayDate}
          <Separator />
          <a
            target='_blank'
            rel='noopener noreferrer'
            href={`https://www.doi.org/${paper.identifier}`}
          >
            DOI
          </a>
          <Separator />
          {paper.creators[0]} et al.
          {pdfLink}
          <Separator />
          <span className='bold'>{paper.citations}</span> citations
        </div>
        <div style={{ width: '40%', textAlign: 'right' }}>
          <span
            className='icon-button bookmark-toggle preview-bookmark'
            {...(!isMobile && {
              'data-tooltip': isBookmarked ? 'Remove bookmark' : 'Add bookmark',
            })}
            onClick={handleToggleBookmark}
          >
            {isBookmarked ? <RemoveBookmarkIcon /> : <BookmarkIcon />}
          </span>
          <span
            className='icon-button cite-toggle'
            onClick={() => copyToClipboard(paper.bibtex)}
            {...(!isMobile && { 'data-tooltip': 'Copy BibTex' })}
          >
            <CiteIcon />
          </span>
        </div>
      </div>
      <div id={`paper-modal-${paper.identifier}`}>
        {showPaperModal && selectedPaper && (
          <PaperModal
            data={selectedPaper}
            onClose={() => {
              setShowPaperModal(false);
              setSelectedPaper(null);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default PaperPreview;
