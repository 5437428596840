import React, { useState, useEffect } from 'react';
import Markdown from 'react-markdown';
import SubstancePropertyModal from '../modals/SubstancePropertyModal';
import { fetchCommonMetadata } from '../../services/api';
import MagnifyingGlass from '../../icons/MagnifyingGlass';
import SkeletonButton from '../common/skeleton_button/SkeletonButton';
import { parseSubstanceProperties } from '../../services/substanceUtils';

const AgentAnswer = ({ answer, substanceProperty }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedSubstance, setSelectedSubstance] = useState(null);
  const [propertiesData, setPropertiesData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!substanceProperty) return;

    setIsLoading(true);
    const substances = parseSubstanceProperties(substanceProperty);

    Promise.all(
      substances.map(async (substance) => {
        if (substance.wdtId) {
          const meta = await fetchCommonMetadata(substance.wdtId, 'en');
          if (meta) {
            return { ...substance, meta };
          }
        }
        return substance;
      }),
    )
      .then(setPropertiesData)
      .finally(() => setIsLoading(false));
  }, [substanceProperty]);

  const handleSubstanceClick = (substance) => {
    setSelectedSubstance(substance);
    setShowModal(true);
  };

  return (
    <div>
      <Markdown>{answer}</Markdown>
      {substanceProperty && (
        <div className='substance-property'>
          {isLoading ? (
            <div className='substance-buttons'>
              {parseSubstanceProperties(substanceProperty).map((_, index) => (
                <SkeletonButton key={index} />
              ))}
            </div>
          ) : propertiesData.length > 0 ? (
            <div className='substance-buttons'>
              {propertiesData.map((substance, index) => (
                <button
                  key={substance.wdtId || index}
                  className='substance-button'
                  onClick={() => handleSubstanceClick(substance)}
                >
                  <span style={{ paddingRight: '2px' }}>{substance.title || 'View'}</span>
                  <MagnifyingGlass />
                </button>
              ))}
            </div>
          ) : null}
        </div>
      )}
      {showModal && selectedSubstance && (
        <SubstancePropertyModal
          data={selectedSubstance}
          onClose={() => {
            setShowModal(false);
            setSelectedSubstance(null);
          }}
        />
      )}
    </div>
  );
};

export default AgentAnswer;
