import React, { useEffect, useContext, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import Markdown from 'react-markdown';
import BookmarkIcon from '../../icons/BookmarkIcon';
import RemoveBookmarkIcon from '../../icons/RemoveBookmarkIcon';
import CiteIcon from '../../icons/CiteIcon';
import Separator from '../common/separator/Separator';
import useToggleBookmarks from '../../hooks/useToggleBookmarks';
import useIsMobile from '../../hooks/useIsMobile';
import SendIcon from '../../icons/SendIcon';
import LoadingSpinner from '../common/loading_spinner/LoadingSpinner';
import { fetchPdfConversation } from '../../services/api';
import { PdfQuestionContext } from '../../contexts/PdfQuestionContext';
import { copyToClipboard } from '../../services/utils';
import './PaperModal.css';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const PaperModal = ({ data, onClose }) => {
  const isMobile = useIsMobile();
  const { isBookmarked, handleToggleBookmark } = useToggleBookmarks(data);
  const { askQuestion, clearContext, loading: pdfQuestionLoading } = useContext(PdfQuestionContext);
  const [questionInput, setQuestionInput] = useState('');
  const [conversationMessages, setConversationMessages] = useState([]);
  const conversationListRef = useRef(null);
  const paperQuestionAbortController = useRef(null);

  useEffect(() => {
    const fetchConversation = async () => {
      if (data && data.identifier) {
        const conversationData = await fetchPdfConversation(data.identifier);
        if (conversationData && conversationData.conversation) {
          setConversationMessages(conversationData.conversation);
        }
      }
    };

    fetchConversation();
  }, [data]);

  useEffect(() => {
    if (conversationListRef.current && conversationMessages.length > 0) {
      const lastMessage = conversationListRef.current.lastElementChild;
      if (lastMessage) {
        lastMessage.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }
  }, [conversationMessages]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        if (paperQuestionAbortController.current) {
          paperQuestionAbortController.current.abort();
        }
        setQuestionInput('');
        onClose();
      } else if (e.key === 'Enter' && questionInput.trim() !== '') {
        console.log('Enter pressed');
        handleAskPaperClick();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      if (paperQuestionAbortController.current) {
        paperQuestionAbortController.current.abort();
      }
      setQuestionInput('');
    };
  }, [onClose]);

  const handleOverlayClick = (e) => {
    if (e.target.className === 'paper-modal-overlay') {
      if (paperQuestionAbortController.current) {
        paperQuestionAbortController.current.abort();
      }
      setQuestionInput('');
      onClose();
    }
  };

  const handleAskPaperClick = async () => {
    const { identifier, pdfUrl } = data;

    if (pdfUrl && identifier && questionInput.trim() !== '') {
      paperQuestionAbortController.current = new AbortController();

      const userMessage = { inputText: questionInput, isUser: true };
      setConversationMessages((prev) => [...prev, userMessage]);

      const currentInput = questionInput;
      setQuestionInput('');

      const result = await askQuestion(identifier, pdfUrl, currentInput, {
        signal: paperQuestionAbortController.current.signal,
      });

      if (result.success && result.data && result.data.response) {
        const botMessage = { inputText: result.data.response, isUser: false };
        setConversationMessages((prev) => [...prev, botMessage]);
      } else if (result.failed) {
        const errorMessage = {
          inputText: `We are very sorry but it appears that our AI agent was not able to access the PDF due to permission restrictions. Please try chatting with another PDF.`,
          isUser: false,
          isError: true,
        };
        setConversationMessages((prev) => [...prev, errorMessage]);
      } else if (result.aborted) {
        console.log('PDF question was aborted');
      } else {
        const errorMessage = {
          inputText: 'Something went wrong with your question. Please try again later.',
          isUser: false,
          isError: true,
        };
        setConversationMessages((prev) => [...prev, errorMessage]);
      }
    } else {
      console.log('Missing identifier or PDF URL for this paper');
    }
  };

  const handleCloseButtonClick = () => {
    if (paperQuestionAbortController.current) {
      paperQuestionAbortController.current.abort();
    }
    setQuestionInput('');
    onClose();
  };

  const handleClearContext = async () => {
    const { identifier } = data;

    if (identifier) {
      if (paperQuestionAbortController.current) {
        paperQuestionAbortController.current.abort();
      }

      const result = await clearContext(identifier);

      if (result.success && result.data) {
        if (result.data.conversation && result.data.conversation.length > 0) {
          setConversationMessages(result.data.conversation);
        } else {
          setConversationMessages([]);
        }
      } else {
        const errorMessage = {
          inputText: 'Failed to clear conversation context. Please try again.',
          isUser: false,
          isError: true,
        };
        setConversationMessages((prev) => [...prev, errorMessage]);
      }
    }
  };

  if (!data) return null;

  const {
    title,
    displayDate,
    creators,
    pdfUrl,
    url,
    content,
    venue,
    citations,
    influentialCitationCount,
    bibtex,
  } = data;

  const restrictedDomains = ['onlinelibrary', 'link.aps.org'];

  const canAskPaper =
    pdfUrl && pdfUrl !== 'none' && !restrictedDomains.some((domain) => pdfUrl.includes(domain));

  return ReactDOM.createPortal(
    <div className='container'>
      <div className='paper-modal-overlay' onClick={handleOverlayClick}>
        <div className='paper-modal-content' onClick={(e) => e.stopPropagation()}>
          <button className='close-button' onClick={handleCloseButtonClick}>
            X
          </button>

          <div className='paper-modal-container'>
            <div className='modal-title'>
              <h2>{title}</h2>
            </div>
            <div className='paper-meta-info'>
              {displayDate}
              <Separator />
              {creators &&
                (creators.length > 10
                  ? `${creators.slice(0, 10).join(', ')}, et al.`
                  : creators.join(', '))}
              <Separator />
              {venue}
            </div>
            <div className='citation-info'>
              {citations} citations
              <Separator />
              {influentialCitationCount} influential citations
            </div>
            <div className='action-buttons-row'>
              <div className='action-buttons-left'>
                {pdfUrl && pdfUrl !== 'none' && (
                  <a
                    className='icon-button'
                    target='_blank'
                    rel='noopener noreferrer'
                    href={pdfUrl}
                  >
                    PDF
                  </a>
                )}
                {url && (
                  <a className='icon-button' target='_blank' rel='noopener noreferrer' href={url}>
                    SemanticScholar
                  </a>
                )}
              </div>
              <div className='action-buttons-right'>
                <span
                  className={`icon-button bookmark-toggle modal-bookmark ${
                    pdfQuestionLoading ? 'disabled' : ''
                  }`}
                  {...(!isMobile && {
                    'data-tooltip': pdfQuestionLoading
                      ? 'Please wait...'
                      : isBookmarked
                      ? 'Remove bookmark'
                      : 'Add bookmark',
                  })}
                  onClick={pdfQuestionLoading ? undefined : handleToggleBookmark}
                >
                  {isBookmarked ? <RemoveBookmarkIcon /> : <BookmarkIcon />}
                </span>
                <span
                  className={`icon-button cite-toggle ${pdfQuestionLoading ? 'disabled' : ''}`}
                  onClick={pdfQuestionLoading ? undefined : () => copyToClipboard(bibtex)}
                  {...(!isMobile && {
                    'data-tooltip': pdfQuestionLoading ? 'Please wait...' : 'Copy BibTex',
                  })}
                >
                  <CiteIcon />
                </span>
              </div>
            </div>
            <div className='modal-content-wrapper'>
              <div className='paper-modal-left'>
                <p className='paper-description'>{content}</p>
              </div>
            </div>

            <div className='paper-modal-body'>
              {conversationMessages.length > 0 &&
                (() => {
                  const renderedMessages =
                    conversationMessages.length > 0 && !conversationMessages[0].isUser
                      ? conversationMessages.slice(1)
                      : conversationMessages;
                  return (
                    <ul ref={conversationListRef} className='paper-modal-messages'>
                      {renderedMessages.map((message, index) => (
                        <li
                          key={index}
                          className={
                            message.isUser ? 'message right appeared' : 'message left appeared'
                          }
                        >
                          <div className='text-wrapper'>
                            <div className='text'>
                              {message.isUser ? (
                                message.inputText
                              ) : (
                                <Markdown>{message.inputText}</Markdown>
                              )}
                            </div>
                          </div>
                          {!message.isUser && <hr />}
                        </li>
                      ))}
                      {pdfQuestionLoading && <LoadingSpinner />}
                    </ul>
                  );
                })()}

              {canAskPaper && (
                <div
                  className='ask-paper-container'
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    gap: '10px',
                  }}
                >
                  {conversationMessages.length > 1 && (
                    <button
                      className={`clear-context-button ${pdfQuestionLoading ? 'disabled' : ''}`}
                      onClick={handleClearContext}
                      disabled={pdfQuestionLoading}
                      {...(!isMobile && { 'data-tooltip': 'Reset Chat' })}
                    >
                      <AddCircleOutlineIcon
                        className={`paper-icon ${pdfQuestionLoading ? 'disabled' : ''}`}
                      />

                      {/*<span className='clear-context-button-text'>New Chat</span>*/}
                    </button>
                  )}
                  <div className='ask-paper-input-wrapper'>
                    <span className='beta-notice'>*This is a beta feature.</span>
                    <input
                      type='text'
                      className='paper-question-input'
                      placeholder='*Chat with paper...'
                      style={{ flexGrow: 1 }}
                      value={questionInput}
                      onChange={(e) => setQuestionInput(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                          e.preventDefault();
                          if (!pdfQuestionLoading && questionInput.trim() !== '') {
                            handleAskPaperClick();
                          }
                        }
                      }}
                    />
                    <button
                      className={`substance-button ask-paper-button send-message ${
                        pdfQuestionLoading || questionInput.trim() === '' ? 'send-disabled' : ''
                      }`}
                      onClick={handleAskPaperClick}
                      {...(!isMobile && { 'data-tooltip': 'Send' })}
                      disabled={pdfQuestionLoading || questionInput.trim() === ''}
                    >
                      <SendIcon />
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.getElementById('modal-root'),
  );
};

export default PaperModal;
