export const getUserId = () => {
  const consentMode = localStorage.getItem('consentMode');
  const hasConsent = consentMode && JSON.parse(consentMode).functionality_storage === 'granted';

  if (hasConsent) {
    return localStorage.getItem('classmate_user_id') || null;
  }

  return null;
};
