import React from 'react';

const RemoveBookmarkIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 26'
    width='32px'
    height='36px'
    id='remove-bookmark-icon'
  >
    <path d='M 6.0097656 2 C 4.9143111 2 4.0097656 2.9025988 4.0097656 3.9980469 L 4 22 L 12 19 L 20 22 L 20 20.556641 L 20 4 C 20 2.9069372 19.093063 2 18 2 L 6.0097656 2 z M 6.0097656 4 L 18 4 L 18 19.113281 L 12 16.863281 L 6.0019531 19.113281 L 6.0097656 4 z' />
    <g fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round'>
      <line x1='8' y1='7.5' x2='16' y2='15.5' />
      <line x1='8' y1='15.5' x2='16' y2='7.5' />
    </g>
  </svg>
);

export default RemoveBookmarkIcon;
