import { useState, useEffect, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { getUserId } from '../services/auth';
import { useConsent } from '../contexts/ConsentContext';
import {
  fetchDialogues as apiFetchDialogues,
  saveDialogue as apiSaveDialogue,
  updateDialogue as apiUpdateDialogue,
  deleteDialogue as apiDeleteDialogue,
} from '../services/api';

export const useDialogues = () => {
  const [dialogues, setDialogues] = useState([]);
  const [loading, setLoading] = useState(false);
  const { hasNecessaryConsent } = useConsent();

  const getUserIdSafely = () => {
    return localStorage.getItem('classmate_user_id');
  };

  const fetchDialogues = useCallback(async () => {
    const userId = getUserIdSafely();
    if (!userId) {
      return;
    }

    setLoading(true);
    try {
      const data = await apiFetchDialogues();
      setDialogues(data?.dialogues ? Object.values(data.dialogues) : []);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, []);

  const createDialogue = useCallback(
    async (dialogue) => {
      const userId = getUserIdSafely();
      if (!userId) {
        return;
      }

      setLoading(true);
      try {
        const dialogueId = dialogue.dialogue_id || uuidv4();
        const session_id = dialogue.session_id || uuidv4();
        const dialogueData = { ...dialogue, dialogue_id: dialogueId, session_id: session_id };
        await apiSaveDialogue(dialogueData);
        setDialogues((prev) => [...prev, dialogueData]);
        await fetchDialogues();
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    },
    [fetchDialogues],
  );

  const updateDialogue = useCallback(
    async (dialogue) => {
      const userId = getUserIdSafely();
      if (!userId) {
        return;
      }

      setLoading(true);
      try {
        if (!dialogue.dialogue_id) {
          throw new Error('Dialogue ID is required for updating');
        }
        await apiUpdateDialogue(dialogue);
        await fetchDialogues();
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    },
    [fetchDialogues],
  );

  const removeDialogue = useCallback(
    async (dialogue) => {
      const userId = getUserIdSafely();
      if (!userId) {
        return;
      }

      setLoading(true);
      try {
        await apiDeleteDialogue(dialogue);
        await fetchDialogues();
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    },
    [fetchDialogues],
  );

  // Initial load based on consent
  useEffect(() => {
    if (hasNecessaryConsent) {
      const userId = getUserIdSafely();
      if (userId) {
        fetchDialogues();
      }
    }
  }, [hasNecessaryConsent, fetchDialogues]);

  // Listen for consent updates
  useEffect(() => {
    const handleConsentUpdate = () => {
      const userId = getUserIdSafely();
      if (userId) {
        fetchDialogues();
      }
    };

    window.addEventListener('consentUpdated', handleConsentUpdate);
    return () => {
      window.removeEventListener('consentUpdated', handleConsentUpdate);
    };
  }, [fetchDialogues]);

  return {
    dialogues,
    loading,
    fetchDialogues,
    createDialogue,
    updateDialogue,
    removeDialogue,
  };
};
