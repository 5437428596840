import { useState, useEffect } from 'react';
import { fetchBookmarks } from '../services/api';
import { useConsent } from '../contexts/ConsentContext';

export const useBookmarks = () => {
  const [bookmarks, setBookmarks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Now this is safe to use even if ConsentProvider isn't rendered yet
  const { hasNecessaryConsent } = useConsent();

  const refreshBookmarks = async () => {
    // Check if user ID exists
    const userId = localStorage.getItem('classmate_user_id');

    // Don't proceed if no user ID
    if (!userId) {
      return;
    }

    setError(null);
    const loadingTimer = setTimeout(() => {
      setLoading(true);
    }, 400);

    try {
      const data = await fetchBookmarks();
      if (data && Array.isArray(data.bookmarks)) {
        setBookmarks(data.bookmarks);
      } else {
        setBookmarks([]);
      }
    } catch (err) {
      setError(err);
    } finally {
      clearTimeout(loadingTimer);
      setLoading(false);
    }
  };

  // Initial load based on consent
  useEffect(() => {
    if (hasNecessaryConsent) {
      refreshBookmarks();
    }
  }, [hasNecessaryConsent]);

  // Listen for consent updates
  useEffect(() => {
    const handleConsentUpdate = () => {
      const userId = localStorage.getItem('classmate_user_id');
      if (userId) {
        refreshBookmarks();
      }
    };

    window.addEventListener('consentUpdated', handleConsentUpdate);
    return () => {
      window.removeEventListener('consentUpdated', handleConsentUpdate);
    };
  }, []);

  return { bookmarks, loading, error, refreshBookmarks };
};
