import React from 'react';

const MagnifyingGlass = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 64 68'>
    <circle cx='27' cy='27' r='16' stroke='#fff' strokeWidth='4' fill='none' />
    <line x1='38' y1='38' x2='58' y2='58' stroke='#fff' strokeWidth='4' strokeLinecap='round' />
  </svg>
);

export default MagnifyingGlass;
