import { useState } from 'react';
import { askPdfQuestion, clearPdfConversationContext } from '../services/api';

export const usePdfQuestion = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);

  const askQuestion = async (doi, pdfUrl, question, options = {}) => {
    setLoading(true);
    setError(null);

    try {
      const data = await askPdfQuestion(doi, pdfUrl, question, options);
      setResponse(data);
      return { data, success: true };
    } catch (err) {
      if (err.name === 'AbortError') {
        console.log('PDF question request was aborted');
        return { success: false, aborted: true };
      } else {
        const errorMessage = err.message || 'Error asking question about PDF';
        console.log('Error in usePdfQuestion:', errorMessage);
        setError(errorMessage);
        return {
          success: false,
          errorMessage,
          error: err,
          failed: true, // Add explicit failed flag for easier checking
        };
      }
    } finally {
      setLoading(false);
    }
  };
  const clearContext = async (doi) => {
    setLoading(true);
    setError(null);

    try {
      const data = await clearPdfConversationContext(doi);
      setResponse(data);
      return { data, success: true };
    } catch (err) {
      const errorMessage = err.message || 'Error clearing conversation context';
      console.log('Error clearing context in usePdfQuestion:', errorMessage);
      setError(errorMessage);
      return {
        success: false,
        errorMessage,
        error: err,
      };
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    error,
    response,
    askQuestion,
    clearContext,
    hasError: error !== null,
    clearError: () => setError(null),
  };
};
