import { getUserId } from './auth';

const endpoint =
  process.env.REACT_APP_API_ENDPOINT || 'https://lass-kg-api.demos.dice-research.org';

/**
 * API Methods for the v2/suggest endpoint
 */

/**
 * Fetches default suggestions without a query
 * @endpoint GET /v2/suggest
 * @param {number} numberOfSuggestions - Number of suggestions to return
 * @returns {Promise<Array>} Array of suggestion objects
 * @example
 * const suggestions = await fetchSuggestions(10);
 */
export const fetchSuggestions = async (numberOfSuggestions) => {
  try {
    const result = await fetch(`${endpoint}/v2/suggest?n=${numberOfSuggestions}`);
    const data = await result.json();
    return data.suggestions || [];
  } catch (error) {
    console.error('Error fetching suggestions:', error);
    return [];
  }
};

/**
 * Fetches suggestions based on a search query
 * @endpoint GET /v2/suggest
 * @param {string} query - Search query string
 * @param {number} numberOfSuggestions - Number of suggestions to return
 * @returns {Promise<Array>} Array of suggestion objects
 * @example
 * const suggestions = await fetchSuggestionsWithQuery("metal", 10);
 */
export const fetchSuggestionsWithQuery = async (query, numberOfSuggestions) => {
  try {
    const result = await fetch(`${endpoint}/v2/suggest?query=${query}&n=${numberOfSuggestions}`);
    const data = await result.json();
    return data.suggestions || [];
  } catch (error) {
    console.error('Error fetching suggestions with query:', error);
    return [];
  }
};

/**
 * API Methods for the v2/autocomplete endpoint
 */

/**
 * Fetches autocompletion suggestions for a query
 * @endpoint GET /v2/autocomplete
 * @param {string} query - Partial query to autocomplete
 * @param {string} sessionId - User session identifier
 * @returns {Promise<string|null>} Autocomplete suggestion or null if none found
 * @example
 * const completion = await fetchAutoComplete("metal", "session-123");
 */
export const fetchAutoComplete = async (query, sessionId) => {
  try {
    const result = await fetch(`${endpoint}/v2/autocomplete?query=${query}`, {
      headers: {
        'Content-Type': 'application/json',
        'Session-Id': sessionId,
      },
    });
    const data = await result.json();
    return data.completion || null;
  } catch (error) {
    console.error('Error fetching autocomplete:', error);
    return null;
  }
};

/**
 * API Methods for the v2/agent-search endpoint
 */

/**
 * Performs an agent search with the given query
 * @endpoint POST /v2/agent-search
 * @param {string} query - Search query
 * @param {string} sessionId - User session identifier
 * @returns {Promise<Object>} Search results containing abstracts and answers
 * @throws {Error} If the search request fails
 * @example
 * const results = await fetchAgentSearch("properties of steel", "session-123");
 */
export const fetchAgentSearch = async (query, sessionId, options = {}) => {
  try {
    const result = await fetch(
      `${endpoint}/v2/agent-search?query=${query}&session_id=${sessionId}`,
      {
        method: 'POST',
        ...options,
      },
    );
    return await result.json();
  } catch (error) {
    if (error.name === 'AbortError') {
      return;
    }
    console.error('Error fetching agent search:', error);
    throw error;
  }
};

/**
 * Fetches and transforms agent search results
 * @endpoint POST /v2/agent-search
 * @param {string} input - Search query
 * @param {string} sessionId - User session identifier
 * @returns {Promise<Object>} Transformed search results with papers and answer
 */
export const fetchAgentSearchWithPapers = async (input, sessionId, options = {}) => {
  const result = await fetchAgentSearch(input, sessionId, options);

  // do not detelte this, this check is important to now return null data after request was aborted
  if (!result) {
    return null;
  }

  const papers = result.abstracts.map((abstract) => ({
    content: abstract.content,
    identifier: abstract.identifier,
    creators: abstract.creators,
    title: abstract.title,
    citations: abstract.citations,
    displayDate: new Date(abstract.publicationDate).getFullYear(),
    url: abstract.url,
    pdfUrl: abstract.pdfUrl,
    bibtex: abstract.bibtex,
    venue: abstract.venue,
    influentialCitationCount: abstract.influentialCitationCount,
  }));

  return {
    papers,
    answer: result.answer,
    markdown_substance_property: result.markdown_substance_property,
  };
};

/**
 * API Methods for the common/metadata endpoint
 */

/**
 * Fetches metadata for a substance from Wikidata entity
 * @endpoint GET /common/metadata
 * @param {string} wdtId - Wikidata entity ID (e.g. "Q716" for Titanium)
 * @param {string} [lang='en'] - Language code for the response
 * @returns {Promise<Object>} Object containing substance metadata including:
 *  - name: substance name
 *  - description: substance description
 *  - imageURL: URL to substance image
 *  - pubchemId: PubChem compound ID
 *  - properties_table: Object containing substance properties
 *  - description_list: Array of descriptions from different sources
 *  - structure_2d_url: URL to 2D structure image
 * @example
 * const metadata = await fetchCommonMetadata("Q716", "en");
 */
export const fetchCommonMetadata = async (wdtId, lang = 'en') => {
  try {
    const response = await fetch(`${endpoint}/common/metadata?wdt_uri=${wdtId}&lang=${lang}`);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching common metadata:', error);
    return null;
  }
};

/**
 * Saves a paper as a bookmark for the current user.
 * Sends a POST request to the backend with the full paper information.
 *
 * @endpoint POST /users/{user_id}/bookmarks
 * @param {Object} paperData - An object containing all paper information to be bookmarked.
 * @returns {Promise<Object|null>} Response data if the bookmark is successfully saved, or null on error.
 * @throws {Error} When user_id is not found or the request fails.
 */
export const savePaperToBookmark = async (paperData) => {
  try {
    const userId = getUserId();
    if (!userId) {
      throw new Error('User ID not found in localStorage');
    }
    const response = await fetch(`${endpoint}/users/${userId}/bookmarks`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(paperData),
    });
    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.detail || 'Error saving bookmark');
    }
    return data;
  } catch (error) {
    console.error('Error saving paper bookmark:', error);
    return null;
  }
};

/**
 * Fetches all bookmark entries for the current user.
 * Sends a GET request to retrieve the user's bookmarks from the backend.
 *
 * @endpoint GET /users/{user_id}/bookmarks
 * @returns {Promise<Object|null>} An object containing the user_id and an array of bookmark entries, or null on error.
 * @throws {Error} When user_id is not found or the request fails.
 */
export const fetchBookmarks = async () => {
  try {
    const userId = getUserId();
    if (!userId) {
      throw new Error('User ID not found in localStorage');
    }
    const response = await fetch(`${endpoint}/users/${userId}/bookmarks`);
    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.detail || 'Error fetching bookmarks');
    }
    return data;
  } catch (error) {
    console.error('Error fetching bookmarks:', error);
    return null;
  }
};

/**
 * Deletes a bookmark for the current user.
 * Sends a DELETE request to the backend to remove a bookmark entry identified by its unique "identifier".
 *
 * @endpoint DELETE /users/{user_id}/bookmarks
 * @param {Object} paper - An object representing the bookmark to delete, which must include a unique "identifier" field.
 * @returns {Promise<Object|null>} Response data if the bookmark is successfully deleted, or null on error.
 * @throws {Error} When user_id is not found or the request fails.
 */
export const deletePaperFromBookmarks = async (paper) => {
  try {
    const userId = getUserId();
    if (!userId) {
      throw new Error('User ID not found in localStorage');
    }
    const response = await fetch(
      `${endpoint}/users/${userId}/bookmarks?identifier=${paper.identifier}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.detail || 'Error deleting bookmark');
    }
    return data;
  } catch (error) {
    console.error('Error deleting bookmark:', error);
    return null;
  }
};

/**
 * Fetches all dialogue entries for the current user.
 * Assumes the backend still supports listing dialogues.
 *
 * @endpoint GET /users/{user_id}/dialogues
 */
export const fetchDialogues = async () => {
  try {
    const userId = getUserId();
    if (!userId) {
      throw new Error('User ID not found in localStorage');
    }
    const response = await fetch(`${endpoint}/users/${userId}/dialogues`);
    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.detail || 'Error fetching dialogues');
    }
    return data;
  } catch (error) {
    console.error('Error fetching dialogues:', error);
    return null;
  }
};

/**
 * Saves a dialogue for the current user.
 * Now requires a unique dialogue_id.
 *
 * @endpoint POST /users/{user_id}/dialogues/{dialogue_id}
 * @param {Object} dialogueData - An object containing dialogue information and a unique "dialogue_id".
 * @returns {Promise<Object|null>} Response data if the dialogue is successfully created, or null on error.
 */
export const saveDialogue = async (dialogueData) => {
  try {
    const userId = getUserId();
    if (!userId) {
      throw new Error('User ID not found in localStorage');
    }
    const { dialogue_id } = dialogueData;
    if (!dialogue_id) {
      throw new Error('Dialogue ID is required');
    }
    const response = await fetch(`${endpoint}/users/${userId}/dialogues/${dialogue_id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(dialogueData),
    });
    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.detail || 'Error creating dialogue');
    }
    return data;
  } catch (error) {
    console.error('Error saving dialogue:', error);
    return null;
  }
};

/**
 * Updates an existing dialogue for the current user.
 * Requires the unique dialogue_id.
 *
 * @endpoint PUT /users/{user_id}/dialogues/{dialogue_id}
 * @param {Object} dialogueData - An object containing updated dialogue info including its "dialogue_id".
 * @returns {Promise<Object|null>} Response data if the dialogue is successfully updated, or null on error.
 */
export const updateDialogue = async (dialogueData) => {
  try {
    const userId = getUserId();
    if (!userId) {
      throw new Error('User ID not found in localStorage');
    }
    const { dialogue_id } = dialogueData;
    if (!dialogue_id) {
      throw new Error('Dialogue ID is required');
    }
    const response = await fetch(`${endpoint}/users/${userId}/dialogues/${dialogue_id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(dialogueData),
    });
    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.detail || 'Error updating dialogue');
    }
    return data;
  } catch (error) {
    console.error('Error updating dialogue:', error);
    return null;
  }
};

/**
 * Deletes a dialogue for the current user.
 * Requires the unique dialogue_id.
 *
 * @endpoint DELETE /users/{user_id}/dialogues/{dialogue_id}
 * @param {Object} dialogue - An object representing the dialogue to delete, which must include "dialogue_id".
 * @returns {Promise<Object|null>} Response data if the dialogue is successfully deleted, or null on error.
 */
export const deleteDialogue = async (dialogue) => {
  try {
    const userId = getUserId();
    if (!userId) {
      throw new Error('User ID not found in localStorage');
    }
    const { dialogue_id } = dialogue;
    if (!dialogue_id) {
      throw new Error('Dialogue ID is required');
    }
    const response = await fetch(`${endpoint}/users/${userId}/dialogues/${dialogue_id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.detail || 'Error deleting dialogue');
    }
    return data;
  } catch (error) {
    console.error('Error deleting dialogue:', error);
    return null;
  }
};

/**
 * Asks a question about a specific PDF document
 * @endpoint POST /users/{user_id}/pdfs/{doi}
 * @param {string} doi - Digital Object Identifier for the paper
 * @param {string} pdfUrl - URL to the PDF file
 * @param {string} question - Question to ask about the PDF document
 * @returns {Promise<Object|null>} Response data with the AI's answer about the PDF, or null on error
 */
export const askPdfQuestion = async (
  doi,
  pdfUrl,
  question = 'What are the key findings in this document?',
  options = {},
) => {
  try {
    const userId = getUserId();
    if (!userId) {
      throw new Error('User ID not found in localStorage');
    }

    const encodedDoi = encodeURIComponent(doi);

    const response = await fetch(`${endpoint}/users/${userId}/pdfs/${encodedDoi}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        pdf_url: pdfUrl,
        user_question: question,
      }),
      signal: options.signal,
    });

    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.detail || 'Error processing PDF question');
    }
    return data;
  } catch (error) {
    if (error.name === 'AbortError') {
      console.log('PDF question request was intentionally aborted');
      throw error;
    } else {
      console.log('Error asking PDF question:', error);
      throw error;
    }
  }
};

/**
 * Fetches conversation history for a specific PDF document
 * @endpoint GET /users/{user_id}/pdfs/{doi}/conversation
 * @param {string} paperId - Digital Object Identifier for the paper
 * @returns {Promise<Object|null>} Response data with the conversation history, or null if none exists
 */
export const fetchPdfConversation = async (paperId) => {
  try {
    const userId = getUserId();
    if (!userId) {
      throw new Error('User ID not found in localStorage');
    }

    const encodedPaperId = encodeURIComponent(paperId);
    const response = await fetch(`${endpoint}/users/${userId}/pdfs/${encodedPaperId}/conversation`);

    if (response.status === 404) {
      return null;
    }

    if (!response.ok) {
      const data = await response.json();
      throw new Error(data.detail || `Error fetching PDF conversation (${response.status})`);
    }

    return await response.json();
  } catch (error) {
    console.log('Error fetching PDF conversation:', error);
    return null;
  }
};

/**
 * Clears the conversation context for a specific PDF document
 * @endpoint POST /users/{user_id}/pdfs/{doi}/conversation/clear_context
 * @param {string} paperId - Digital Object Identifier for the paper
 * @returns {Promise<Object|null>} Response data with the empty conversation, or null on error
 */
export const clearPdfConversationContext = async (paperId) => {
  try {
    const userId = getUserId();
    if (!userId) {
      throw new Error('User ID not found in localStorage');
    }

    const encodedPaperId = encodeURIComponent(paperId);
    const response = await fetch(
      `${endpoint}/users/${userId}/pdfs/${encodedPaperId}/conversation/clear_context`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    if (!response.ok) {
      const data = await response.json();
      throw new Error(
        data.detail || `Error clearing PDF conversation context (${response.status})`,
      );
    }

    return await response.json();
  } catch (error) {
    console.log('Error clearing PDF conversation context:', error);
    return null;
  }
};
