import { useContext } from 'react';
import { savePaperToBookmark, deletePaperFromBookmarks } from '../services/api';
import { BookmarksContext } from '../contexts/BookmarksContext';

const useToggleBookmarks = (paper) => {
  const { bookmarks, refreshBookmarks } = useContext(BookmarksContext);
  const isBookmarked = bookmarks.some((b) => b.identifier === paper.identifier);

  const handleToggleBookmark = () => {
    if (isBookmarked) {
      deletePaperFromBookmarks(paper)
        .then(() => refreshBookmarks())
        .catch((err) => console.error(err));
    } else {
      savePaperToBookmark(paper)
        .then(() => refreshBookmarks())
        .catch((err) => console.error(err));
    }
  };

  return { isBookmarked, handleToggleBookmark };
};

export default useToggleBookmarks;
