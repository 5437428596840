import React, { useEffect, useRef } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import './OptionsModal.css';

const OptionsModal = ({ anchorEl, onClose, onDelete, dialogueId }) => {
  const modalRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onClose]);

  if (!anchorEl) return null;
  const rect = anchorEl.getBoundingClientRect();

  return (
    <div
      className='options-modal'
      ref={modalRef}
      style={{
        position: 'fixed',
        top: `${rect.top - 5}px`,
        left: `${rect.right}px`,
      }}
    >
      <div className='options-modal-content'>
        <button
          className='option-button delete-dialogue'
          onClick={() => {
            onDelete(dialogueId);
            onClose();
          }}
        >
          Delete Dialogue <DeleteIcon style={{ marginRight: '5px' }} />
        </button>
      </div>
    </div>
  );
};

export default OptionsModal;
