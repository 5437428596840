import React, { useState, useRef, createRef, useEffect, useContext } from 'react';
import '../../assets/css/Chat.css';
import { useTranslation } from 'react-i18next';
import AgentAnswer from './AgentAnswer';
import MessageList from './MessageList';
import ChatFooter from './ChatFooter';
import { useAutoComplete } from '../../hooks/useAutoComplete';
import { useUIHandlers } from '../../hooks/useUIHandlers';
import { DialoguesContext } from '../../contexts/DialoguesContext';
import { ChatMessagesContext } from '../../contexts/MessagesContext';
import { SessionContext } from '../../contexts/SessionContext';
import { SuggestionsContext } from '../../contexts/SuggestionsContext';
import { v4 as uuidv4 } from 'uuid';
import { serializeMessage } from '../../services/utils';
import { fetchAgentSearchWithPapers } from '../../services/api';
import useIsMobile from '../../hooks/useIsMobile';

const nPapers = process.env.N_PAPERS || 8;

export default function ChatWindow({ dialogueId, setDialogueId, abortControllerRef }) {
  const isMobile = useIsMobile();

  // 1. Core hooks
  const { t } = useTranslation();
  const { sessionId } = useContext(SessionContext);
  const [inputText, setInputText] = useState('');

  // 2. Custom hooks
  const { completions, setCompletions } = useAutoComplete(inputText, sessionId);
  const { suggestions, setSuggestions } = useContext(SuggestionsContext);
  const { createDialogue, updateDialogue } = useContext(DialoguesContext);
  const {
    messages,
    leftoverMessages,
    loading,
    setLoading,
    addMessage,
    addPaperMessages,
    showMorePapers,
    clearLeftoverMessages,
  } = useContext(ChatMessagesContext);

  // 3. API helper
  const fetchData = async (input) => {
    // Create a new AbortController before making a request
    abortControllerRef.current = new AbortController();
    setLoading(true);
    try {
      return await fetchAgentSearchWithPapers(input, sessionId, {
        signal: abortControllerRef.current.signal,
      });
    } catch (error) {
      if (error.name === 'AbortError') {
        // Handle aborted request if needed.
        console.log('Request aborted', error);
      } else {
        throw error;
      }
    } finally {
      setLoading(false);
    }
  };

  const cancelRequest = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
  };

  // Release controller when component unmounts, if needed
  useEffect(() => {
    return () => cancelRequest();
  }, []);

  // 4. onSendClick: process user message and agent response, update dialogue
  const onSendClick = async (e) => {
    e.preventDefault();
    if (!inputText) return;
    if (loading) return;

    const input = inputText;
    setInputText('');
    addMessage(input, true);
    setSuggestions([]);
    setCompletions([]);
    clearLeftoverMessages();

    // Fetch and process response
    setLoading(true);
    setTimeout(() => {
      // this timeout is a hack to make sure the loading indicator is rendered after setting loading to true
      const loadingIndicator = document.getElementById('loading-text-indicaor');
      if (loadingIndicator) {
        loadingIndicator.scrollIntoView({
          behavior: 'smooth',
        });
      }
    }, 50);
    const result = await fetchData(input);
    setLoading(false);

    if (!result) return;

    // Add responses to chat
    addMessage(
      <AgentAnswer answer={result.answer} substanceProperty={result.markdown_substance_property} />,
      false,
    );
    addPaperMessages(result.papers, nPapers);

    // serialize messages and update dialogue
    const agentResponse = {
      answer: result.answer,
      substanceProperty: result.markdown_substance_property,
      papers: result.papers,
    };

    let serializedMessages = messages.map(serializeMessage).filter(Boolean);

    //todo: fix this hacky solution to reconstruct the papers dialogue
    let groupedMessages = [];
    let i = 0;
    while (i < serializedMessages.length) {
      const message = serializedMessages[i];
      if (message.isUser) {
        groupedMessages.push(message);
        i++;
      } else {
        // Start of a bot answer – treat this as the base answer.
        const baseBotMessage = { ...message };
        const papersToAdd = [];
        let j = i + 1;
        // Collect all following bot messages until a user message is encountered.
        while (j < serializedMessages.length && !serializedMessages[j].isUser) {
          papersToAdd.push(serializedMessages[j].inputText);
          j++;
        }
        // Append collected papers to the base bot message's papers array.
        if (baseBotMessage.inputText && Array.isArray(baseBotMessage.inputText.papers)) {
          baseBotMessage.inputText.papers = baseBotMessage.inputText.papers.concat(papersToAdd);
        }
        groupedMessages.push(baseBotMessage);
        // Skip over the bot messages that have been grouped.
        i = j;
      }
    }
    serializedMessages = groupedMessages;

    const conversation = [
      ...serializedMessages,
      { inputText: input, isUser: true },
      { inputText: agentResponse, isUser: false },
    ];

    if (!dialogueId) {
      const newDialogueId = uuidv4();
      setDialogueId(newDialogueId);
      await createDialogue({
        dialogue_id: newDialogueId,
        session_id: sessionId,
        conversation,
      });
    } else {
      await updateDialogue({
        dialogue_id: dialogueId,
        session_id: sessionId,
        conversation,
      });
    }
  };

  // 5. UI handlers
  const {
    handleChange,
    handleKeyPress: onInputKeyPress,
    handleSuggestionsClick: onSuggestionsClick,
  } = useUIHandlers(setInputText, onSendClick, setCompletions);

  // 6. Refs
  const messagesRef = useRef(null);
  const inputRef = createRef(null);
  const isLoadMoreRef = useRef(false);

  // 8. Handle show more papers and scroll into view
  const handleShowMorePapersClick = () => {
    isLoadMoreRef.current = true;
    showMorePapers(nPapers);
  };

  useEffect(() => {
    if (messages.length > 0 && !isLoadMoreRef.current) {
      const userMessages = messagesRef.current?.querySelectorAll(
        '.messages > .message.right.appeared',
      );
      if (userMessages?.length) {
        const lastUserMessage = userMessages[userMessages.length - 1];
        const firstBotMessage = lastUserMessage?.nextElementSibling;
        if (firstBotMessage) {
          firstBotMessage.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            paddingTop: '50px',
          });
        }
      }
    }
    isLoadMoreRef.current = false;
  }, [messages]);

  // 9. Render
  return (
    <>
      <div className='container'>
        <div className='row'>
          <div className='col col-lg-12 chat-window' style={{ paddingLeft: 0 }}>
            {messages && messages.length > 0 && (
              <MessageList
                messages={messages}
                leftoverMessages={leftoverMessages}
                showMorePapers={handleShowMorePapersClick}
                messagesRef={messagesRef}
                loading={loading}
              />
            )}

            {!isMobile ? (
              <div
                className={`${
                  !messages || messages.length === 0
                    ? 'desktop-input-container-beginning'
                    : 'desktop-input-container'
                }`}
              >
                <ChatFooter
                  inputText={inputText}
                  completions={completions}
                  handleChange={handleChange}
                  onInputKeyPress={onInputKeyPress}
                  inputRef={inputRef}
                  onSendClick={onSendClick}
                  chatBegin={messages && !messages.length > 0}
                  onSuggestionsClick={onSuggestionsClick}
                  suggestions={suggestions}
                  loading={loading}
                  t={t}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {isMobile ? (
        <div
          className={`${
            !messages || messages.length === 0 ? 'input-container-beginning' : 'input-container'
          }`}
        >
          <ChatFooter
            inputText={inputText}
            completions={completions}
            handleChange={handleChange}
            onInputKeyPress={onInputKeyPress}
            inputRef={inputRef}
            onSendClick={onSendClick}
            chatBegin={messages && !messages.length > 0}
            onSuggestionsClick={onSuggestionsClick}
            suggestions={suggestions}
            loading={loading}
            t={t}
          />
        </div>
      ) : null}
    </>
  );
}
