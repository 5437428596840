import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import DefaultPerson from '../../../icons/DefaultPerson';
import './About.css';
import SearchIcon from '@mui/icons-material/Search';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import DescriptionIcon from '@mui/icons-material/Description';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

function About() {
  const [activeSection, setActiveSection] = useState('about');
  const [menuOpen, setMenuOpen] = useState(false);
  const [headerScrolled, setHeaderScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setHeaderScrolled(window.scrollY > 50);

      // Determine active section based on scroll position
      const sections = ['about', 'team', 'timeline', 'acknowledgements'];
      const scrollPosition = window.scrollY + 100;

      // Check if we're at the bottom of the page
      const isAtBottom = window.innerHeight + window.scrollY >= document.body.offsetHeight - 100;

      if (isAtBottom) {
        // If at the bottom, set the last section as active
        setActiveSection('acknowledgements');
        return;
      }

      // Regular section detection
      for (const section of sections) {
        const element = document.getElementById(section);
        if (
          element &&
          scrollPosition >= element.offsetTop &&
          scrollPosition < element.offsetTop + element.offsetHeight
        ) {
          setActiveSection(section);
          break;
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      // Special case for acknowledgements section
      if (sectionId === 'acknowledgements') {
        // Scroll to bottom of the page for acknowledgements
        window.scrollTo({
          top: document.body.offsetHeight,
          behavior: 'smooth',
        });
      } else {
        // Regular scroll for other sections
        window.scrollTo({
          top: element.offsetTop - 70,
          behavior: 'smooth',
        });
      }
      setActiveSection(sectionId);
      setMenuOpen(false);
    }
  };

  const activeTeamMembers = [
    {
      name: 'Aleksandr Perevalov',
      role: 'Team Lead',
      photo: process.env.PUBLIC_URL + '/img/team/aleksandr_perevalov_new.jpg',
    },
    {
      name: 'Jonas Wagner',
      role: 'Developer',
      photo: process.env.PUBLIC_URL + '/img/team/jonas_wagner.jpg',
    },
  ];

  const previousTeamMembers = [
    {
      name: 'Jiveshwari Chinchghare',
      role: 'Developer',
      // No photo property provided
    },
    {
      name: 'Amal Nimmy Lal',
      role: 'Developer',
      photo: process.env.PUBLIC_URL + '/img/team/amal.jpeg',
    },
    {
      name: 'Aryman Deshwal',
      role: 'Developer',
      photo: process.env.PUBLIC_URL + '/img/team/aryman.jpg',
    },
    {
      name: 'Mouli Krishna Chekkaplli',
      role: 'Developer',
      // No photo property provided
    },
    {
      name: 'Shivam Sharma',
      role: 'Developer',
      // No photo property provided
    },
  ];

  const timelineMilestones = [
    {
      date: 'March 2022',
      fullDescription:
        '[Software Campus](https://softwarecampus.de/) project application submitted.',
      description: 'Software Campus project application submitted.',
      links: [{ text: 'Software Campus', url: 'https://softwarecampus.de/' }],
    },
    {
      date: 'June 2022',
      fullDescription:
        'Project approved with 100,000 EUR funding and partnership with [Holtzbrinck](https://holtzbrinck.com/).',
      description:
        '**Project approved** with 100,000 EUR funding and partnership with Holtzbrinck.',
      links: [{ text: 'Holtzbrinck', url: 'https://holtzbrinck.com/' }],
    },
    {
      date: 'Autumn 2022',
      fullDescription:
        'Strategic negotiations conducted with [Holtzbrinck](https://holtzbrinck.com/) and [Springer Nature](https://www.springernature.com/gp).',
      description: 'Strategic negotiations conducted with Holtzbrinck and Springer Nature.',
      links: [
        { text: 'Holtzbrinck', url: 'https://holtzbrinck.com/' },
        { text: 'Springer Nature', url: 'https://www.springernature.com/gp' },
      ],
    },
    {
      date: 'February 2023',
      fullDescription:
        'Official project kickoff: established collaboration with [Springer Materials](https://materials.springer.com/).',
      description:
        'Official **project kickoff** established collaboration with Springer Materials.',
      links: [{ text: 'Springer Materials', url: 'https://materials.springer.com/' }],
    },
    {
      date: 'Spring 2023',
      fullDescription: 'Conducted initial industry partner meetings and recruitment interviews.',
      description: 'Conducted initial industry partner meetings and recruitment interviews.',
      links: [],
    },
    {
      date: 'Summer 2023',
      fullDescription: 'Expanded team to 3 members and commenced development phase.',
      description: '**Expanded team** to 3 members and commenced development phase.',
      links: [],
    },
    {
      date: 'Autumn 2023',
      fullDescription:
        'Successfully launched Semantic Search Engine v1 with Knowledge Graphs integration.',
      description:
        'Successfully launched **Semantic Search Engine v1** with Knowledge Graphs integration.',
      links: [],
    },
    {
      date: 'Winter 2024',
      fullDescription:
        '[Semantic Search v1 Demo Daper](https://2025.eswc-conferences.org/wp-content/uploads/2024/05/77770270.pdf) accepted for publication.',
      description: 'Semantic Search v1 Demo Paper accepted for publication.',
      links: [
        {
          text: 'Semantic Search v1 Demo Paper',
          url: 'https://2025.eswc-conferences.org/wp-content/uploads/2024/05/77770270.pdf',
        },
      ],
    },
    {
      date: 'Summer 2024',
      fullDescription:
        'Launched enhanced Semantic Search Engine v2 with Retrieval Augmented Generation (RAG) capabilities.',
      description:
        'Launched enhanced **Semantic Search Engine v2** with Retrieval Augmented Generation (RAG) capabilities.',
      links: [],
    },
    {
      date: 'Autumn 2024',
      fullDescription: 'Expanded project team to 5 members to accelerate development.',
      description: '**Expanded project team** to 5 members to accelerate development.',
      links: [],
    },
    {
      date: 'February 2025',
      fullDescription: 'Officially released Materials AI Agent to the public.',
      description: 'Officially released **Materials AI Agent** to the public.',
      links: [],
    },
    {
      date: 'Spring 2025',
      fullDescription: 'Launched beta release and conducted comprehensive user studies.',
      description: '**Launched beta** release and conducted comprehensive user studies.',
      links: [],
    },
  ];

  const acknowledgements = [
    {
      name: 'Software Campus',
      image: process.env.PUBLIC_URL + '/img/ack/swc.jpg',
      url: 'https://softwarecampus.de/',
    },
    {
      name: 'DICE Research Group',
      image: process.env.PUBLIC_URL + '/img/ack/dice.jpg',
      url: 'https://dice-research.org/',
    },
    {
      name: 'University of Paderborn',
      image: process.env.PUBLIC_URL + '/img/ack/upb.jpg',
      url: 'https://www.uni-paderborn.de/',
    },
    {
      name: 'holtzbrink Publishing Group',
      image: process.env.PUBLIC_URL + '/img/ack/holtzbrinck.jpg',
      url: 'https://holtzbrinck.com/de/',
    },
    {
      name: 'Web & Software Engineering Research Group',
      image: process.env.PUBLIC_URL + '/img/ack/wse.png',
      url: 'https://wse-research.org/',
    },
    {
      name: 'Springer Materials',
      image: process.env.PUBLIC_URL + '/img/ack/springer_materials.svg',
      url: 'https://materials.springer.com/',
    },
  ];

  const renderDescription = (milestone) => {
    if (!milestone.links || milestone.links.length === 0) {
      return parseBoldText(milestone.description);
    }

    const parts = [];
    let remainingText = milestone.description;

    milestone.links.forEach((link, linkIndex) => {
      const textIndex = remainingText.indexOf(link.text);

      if (textIndex !== -1) {
        if (textIndex > 0) {
          parts.push(
            <React.Fragment key={`text-before-link-${linkIndex}`}>
              {parseBoldText(remainingText.substring(0, textIndex))}
            </React.Fragment>,
          );
        }

        parts.push(
          <a key={link.url} href={link.url} target='_blank' rel='noopener noreferrer'>
            {link.text}
          </a>,
        );
        remainingText = remainingText.substring(textIndex + link.text.length);
      }
    });

    // Add any remaining text
    if (remainingText) {
      parts.push(
        <React.Fragment key='remaining-text'>{parseBoldText(remainingText)}</React.Fragment>,
      );
    }

    return <>{parts}</>;
  };

  // Function to parse markdown-style bold text (**text**)
  const parseBoldText = (text) => {
    if (!text || !text.includes('**')) return text;

    const parts = [];
    const boldPattern = /\*\*(.*?)\*\*/g;
    let lastIndex = 0;
    let match;
    let partIndex = 0;

    while ((match = boldPattern.exec(text)) !== null) {
      // Add text before the bold part
      if (match.index > lastIndex) {
        parts.push(
          <React.Fragment key={`text-part-${partIndex++}`}>
            {text.substring(lastIndex, match.index)}
          </React.Fragment>,
        );
      }

      // Add the bold text
      parts.push(
        <span className='timeline-bold' key={`bold-part-${partIndex++}`}>
          {match[1]}
        </span>,
      );

      lastIndex = match.index + match[0].length;
    }

    // Add any remaining text
    if (lastIndex < text.length) {
      parts.push(
        <React.Fragment key={`text-part-${partIndex}`}>{text.substring(lastIndex)}</React.Fragment>,
      );
    }

    return parts.length > 0 ? <>{parts}</> : text;
  };

  // Split timeline milestones into left and right columns in alternating order,
  // with proper vertical alignment
  const splitZigzagTimelineItems = () => {
    const leftColumn = [];
    const rightColumn = [];

    // Create a copy of the original timeline milestones array
    const milestones = [...timelineMilestones];

    // Calculate maximum vertical position to set container height
    let maxVerticalPosition = 0;

    // Iterate through all milestones in order and alternate between left and right columns
    milestones.forEach((milestone, index) => {
      const verticalPos = Math.floor(index / 2) * 150; // Base vertical position

      const enhancedMilestone = {
        ...milestone,
        animationIndex: index,
        verticalPosition: verticalPos,
      };

      // Update max vertical position
      if (verticalPos > maxVerticalPosition) {
        maxVerticalPosition = verticalPos;
      }

      if (index % 2 === 0) {
        leftColumn.push(enhancedMilestone);
      } else {
        rightColumn.push(enhancedMilestone);
      }
    });

    // Calculate the last item's position (accounting for the 65px offset in right column)
    const lastItemPosition =
      milestones.length % 2 === 0
        ? maxVerticalPosition + 65 // Last item is in right column (with offset)
        : maxVerticalPosition; // Last item is in left column

    // Add about 150px padding to accommodate the item height
    const timelineHeight = lastItemPosition + 150;

    return { leftColumn, rightColumn, timelineHeight };
  };

  const renderTimelineMobile = () => {
    return (
      <div className='timeline'>
        {timelineMilestones.map((milestone, index) => (
          <div className='timeline-item' key={milestone.date} style={{ '--item-index': index }}>
            <div className='timeline-date'>{milestone.date}</div>
            <p className='timeline-description'>{renderDescription(milestone)}</p>
          </div>
        ))}
      </div>
    );
  };

  const renderTimelineDesktop = () => {
    const { leftColumn, rightColumn, timelineHeight } = splitZigzagTimelineItems();

    return (
      <div className='timeline' style={{ height: `${timelineHeight}px` }}>
        <div className='timeline-column'>
          {leftColumn.map((milestone) => (
            <div
              className='timeline-item'
              key={milestone.date}
              style={{
                '--item-index': milestone.animationIndex,
                top: `${milestone.verticalPosition}px`,
              }}
            >
              <div className='timeline-date'>{milestone.date}</div>
              <p className='timeline-description'>{renderDescription(milestone)}</p>
            </div>
          ))}
        </div>
        <div className='timeline-column'>
          {rightColumn.map((milestone) => (
            <div
              className='timeline-item'
              key={milestone.date}
              style={{
                '--item-index': milestone.animationIndex,
                top: `${milestone.verticalPosition}px`,
              }}
            >
              <div className='timeline-date'>{milestone.date}</div>
              <p className='timeline-description'>{renderDescription(milestone)}</p>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className='page-container'>
      <header
        className={`page-header page-header-about ${headerScrolled ? 'header-scrolled' : ''}`}
      >
        <Link to='/' className='page-header-link'>
          <span className='link-blue'>Materials</span> AI Agent
          <img
            alt='Materials Science Logo'
            className='header-logo'
            src={process.env.PUBLIC_URL + '/img/chatsymbol_transparent.png'}
          />
        </Link>

        <nav className={`page-nav ${menuOpen ? 'nav-open' : ''}`}>
          <ul className='nav-items'>
            <li className={activeSection === 'about' ? 'active' : ''}>
              <button onClick={() => scrollToSection('about')}>About</button>
            </li>
            <li className={activeSection === 'team' ? 'active' : ''}>
              <button onClick={() => scrollToSection('team')}>Team</button>
            </li>
            <li className={activeSection === 'timeline' ? 'active' : ''}>
              <button onClick={() => scrollToSection('timeline')}>Timeline</button>
            </li>
            <li className={activeSection === 'acknowledgements' ? 'active' : ''}>
              <button onClick={() => scrollToSection('acknowledgements')}>Partners</button>
            </li>
          </ul>
        </nav>

        <button className='mobile-menu-toggle' onClick={() => setMenuOpen(!menuOpen)}>
          {menuOpen ? <CloseIcon /> : <MenuIcon />}
        </button>
      </header>
      <main>
        {/* Hero Section - Placed directly in main */}
        <div className='hero-section'>
          <div className='hero-bg-pattern'></div>
          <div className='hero-content'>
            <h2 className='hero-title'>Transforming Materials Research</h2>
            <p className='hero-description'>
              Helping scientists cut through the noise with our AI-powered research assistant that
              delivers targeted insights and deeper understanding of materials science literature.
            </p>

            <div className='hero-buttons'>
              <Link to='/' className='hero-button'>
                <DescriptionIcon className='hero-button-icon' />
                Try the Materials AI Agent
              </Link>
            </div>

            <div className='hero-stats'>
              <div className='hero-stat'>
                <div className='hero-stat-number'>3M+</div>
                <div className='hero-stat-label'>Research Papers</div>
              </div>
              <div className='hero-stat'>
                <div className='hero-stat-number'>100%</div>
                <div className='hero-stat-label'>Research Focused</div>
              </div>
            </div>
          </div>
        </div>

        <div>
          {/* About Content Section */}
          <div className='section' id='about'>
            <h1 className='page-content-headline'>
              <span className='link-blue'>About</span> Us
            </h1>
            <div className='section-inner intro-section'>
              <div className='intro-grid'>
                <div className='intro-text'>
                  <div className='feature-heading'>
                    <div className='feature-icon'>
                      <EmojiObjectsIcon fontSize='small' />
                    </div>
                    About Our Platform
                  </div>
                  <p className='intro-paragraph'>
                    Materials AI Agent is an{' '}
                    <span className='intro-highlight'>academic search assistant</span> for materials
                    scientists that provides topic-level and paper-level insights helping them to
                    avoid wandering among pointless search results.
                  </p>
                </div>
                <div className='intro-text'>
                  <div className='feature-heading'>
                    <div className='feature-icon'>
                      <AnalyticsIcon fontSize='small' />
                    </div>
                    How It Works
                  </div>
                  <p className='intro-paragraph'>
                    It extends the functionality of Google Scholar-like tools with{' '}
                    <span className='intro-highlight'>enhancing search results</span> using machine
                    learning methods and large language models, and delivering{' '}
                    <span className='intro-highlight'>materials science-specific metadata,</span>{' '}
                    which is driven by our knowledge graph technology.
                  </p>
                </div>

                <div className='feature-category'>
                  <div className='feature-heading'>
                    <div className='feature-icon'>
                      <SearchIcon fontSize='small' />
                    </div>
                    Currently Available Features
                  </div>
                  <ul className='feature-list'>
                    <li className='feature-item'>
                      General literature research and dialogue with the results
                    </li>
                    <li className='feature-item'>
                      Ask this paper feature - run dialogue with a paper of your interest
                    </li>
                    <li className='feature-item'>
                      Recognizing substances and properties in users queries and presenting metadata
                      on them
                    </li>
                    <li className='feature-item'>Bookmarks and history management</li>
                  </ul>
                </div>

                <div className='feature-category'>
                  <div className='feature-heading'>
                    <div className='feature-icon'>
                      <AutoGraphIcon fontSize='small' />
                    </div>
                    In Development
                  </div>
                  <ul className='feature-list'>
                    <li className='feature-item'>
                      Processing substance and property focused queries e.g Cd Ni phase diagram or
                      CaCO3 Crystal Structure
                    </li>
                    <li className='feature-item'>
                      Deep research feature - extracting structured information from research papers
                      and presenting it as a systematic survey to the user
                    </li>
                  </ul>
                </div>
              </div>

              <div className='call-to-action'>
                <p className='cta-text'>
                  Whether you are a <span className='highlight-text'>researcher</span>,{' '}
                  <span className='highlight-text'>student</span>,{' '}
                  <span className='highlight-text'>professor</span>, or{' '}
                  <span className='highlight-text'>industry professional</span>, the Materials AI
                  Agent is here to streamline your communication with the recent scientific advances
                  making your work more efficient and insightful.
                </p>
                <div className='cta-contact'>
                  <span className='cta-email-label'>Contact us:</span>
                  <a href='mailto:contact@perevalov.com' className='cta-email'>
                    contact@perevalov.com
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className='section' id='team'>
            <h2 className='page-content-headline'>
              <span className='link-blue'>Team</span>
            </h2>
            <div className='section-inner'>
              <div className='team-segregation'>
                <h3 className='team-subheadline'>
                  <span className='link-blue'>Active</span> Members
                </h3>
                <div className='team-container'>
                  {activeTeamMembers.map((member) => (
                    <div className='team-member' key={member.name}>
                      <div className='team-photo-container'>
                        {member.photo ? (
                          <img className='team-photo' src={member.photo} alt={member.name} />
                        ) : (
                          <DefaultPerson className='team-photo' />
                        )}
                      </div>
                      <div className='team-info'>
                        <h3 className='team-name'>{member.name}</h3>
                        <p className='team-role'>{member.role}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className='team-segregation'>
                <h3 className='team-subheadline'>
                  <span className='link-blue'>Previous</span> Members
                </h3>
                <div className='team-container'>
                  {previousTeamMembers.map((member) => (
                    <div className='team-member' key={member.name}>
                      <div className='team-photo-container'>
                        {member.photo ? (
                          <img className='team-photo' src={member.photo} alt={member.name} />
                        ) : (
                          <DefaultPerson className='team-photo' />
                        )}
                      </div>
                      <div className='team-info'>
                        <h3 className='team-name'>{member.name}</h3>
                        <p className='team-role'>{member.role}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className='section' id='timeline'>
            <h2 className='page-content-headline'>
              <span className='link-blue'>Timeline</span>
            </h2>
            <div className='section-inner'>
              <div className='d-block d-md-none'>{renderTimelineMobile()}</div>
              <div className='d-none d-md-block'>{renderTimelineDesktop()}</div>
            </div>
          </div>
          <div className='section' id='acknowledgements'>
            <h2 className='page-content-headline'>
              <span className='link-blue'>Acknowledgements</span>
            </h2>
            <div className='section-inner acknowledgements'>
              {acknowledgements.map((partner) => (
                <a href={partner.url} key={partner.name} target='_blank' rel='noopener noreferrer'>
                  <img src={partner.image} alt={partner.name} className='acknowledgement-logo' />
                </a>
              ))}
            </div>
          </div>
        </div>
      </main>
      <footer className='page-footer'>
        <div className='page-footer-content'>
          <div className='footer-links'>
            <Link to='/'>Chat</Link>
            <Link to='/imprint'>Imprint</Link>
            <Link to='/privacy'>Privacy</Link>
          </div>
          <div className='footer-copyright'>
            <span className='footer-copyright-icon'>©</span>
            {new Date().getFullYear()} Materials AI Agent. All rights reserved.
          </div>
        </div>
      </footer>
    </div>
  );
}

export default About;
