import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import {
  useMediaQuery,
  Drawer,
  Typography,
  IconButton,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemSecondaryAction,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import HistoryIcon from '@mui/icons-material/History';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PaperModal from '../../modals/PaperModal';
import OptionsModal from '../../modals/OptionsModal';
import AgentAnswer from '../../chat/AgentAnswer';
import PaperPreview from '../../chat/paper_preview/PaperPreview';
import { BookmarksContext } from '../../../contexts/BookmarksContext';
import { DialoguesContext } from '../../../contexts/DialoguesContext';
import { ChatMessagesContext } from '../../../contexts/MessagesContext';
import { SessionContext } from '../../../contexts/SessionContext';
import { SuggestionsContext } from '../../../contexts/SuggestionsContext';
import { truncateTitle } from '../../../services/utils';
import useIsMobile from '../../../hooks/useIsMobile';
import './Sidebar.css';
import { Link } from 'react-router-dom';

export default function Sidebar({ expanded, setExpanded, setDialogueId, cancelRequest }) {
  const isMobile = useIsMobile();
  const isDesktop = useMediaQuery('(min-width:768px)');
  const [mobileOpen, setMobileOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('history');
  const [showModal, setShowModal] = useState(false);
  const [selectedDialogueId, setSelectedDialogueId] = useState(null);
  const [selectedBookmark, setSelectedBookmark] = useState(null);
  const [optionsAnchorEl, setOptionsAnchorEl] = useState(null);

  const { bookmarks, loading, refreshBookmarks } = useContext(BookmarksContext);
  const { dialogues, fetchDialogues, removeDialogue } = useContext(DialoguesContext);
  const { clearMessages, setMessages } = useContext(ChatMessagesContext);
  const { resetSession, setNewSession } = useContext(SessionContext);
  const { setSuggestions } = useContext(SuggestionsContext);

  const { t } = useTranslation();

  useEffect(() => {
    if (isDesktop) {
      setMobileOpen(false);
    }
  }, [isDesktop]);

  useEffect(() => {
    if (activeTab === 'history') {
      fetchDialogues();
    }
  }, [activeTab, fetchDialogues]);

  const toggleDrawer = () => {
    if (isDesktop) {
      setExpanded((prev) => !prev);
    } else {
      setMobileOpen((prev) => !prev);
    }
  };

  const expandSidebar = (tab) => {
    setActiveTab(tab);
    setExpanded(true);
  };

  const handleBookmarkClick = (bookmark) => {
    setSelectedBookmark(bookmark);
    setShowModal(true);
  };

  const handleMoreClick = (event, dialogueId) => {
    event.stopPropagation();
    setOptionsAnchorEl(event.currentTarget);
    setSelectedDialogueId(dialogueId);
  };

  const handleCloseOptions = () => {
    setOptionsAnchorEl(null);
    setSelectedDialogueId(null);
  };

  const handleDeleteDialogue = async (dialogueId) => {
    try {
      await removeDialogue({ dialogue_id: dialogueId });
    } catch (error) {
      console.error('Failed to delete dialogue:', error);
    }
  };

  const handleStartNewConversation = async () => {
    cancelRequest();

    resetSession();
    clearMessages();
    setSuggestions(null);
    const newDialogueId = uuidv4();
    setDialogueId(newDialogueId);
    toggleDrawer();
  };

  const loadDialogueState = (dialogue) => {
    cancelRequest();

    if (!dialogue) return;
    setDialogueId(dialogue.dialogue_id);
    if (dialogue.session_id) {
      setNewSession(dialogue.session_id);
    }
    clearMessages();

    const rebuiltConversation = [];

    dialogue.conversation?.forEach((msg) => {
      // For user messages, just push them as is
      if (msg.isUser) {
        rebuiltConversation.push(msg);
      } else if (msg.inputText && typeof msg.inputText === 'object' && msg.inputText.answer) {
        // 1) Push the agent's answer as one message
        rebuiltConversation.push({
          ...msg,
          inputText: (
            <AgentAnswer
              answer={msg.inputText.answer}
              substanceProperty={msg.inputText.substanceProperty}
            />
          ),
        });

        // 2) Push each paper as a separate message
        msg.inputText.papers?.forEach((paper) => {
          rebuiltConversation.push({
            isUser: false,
            inputText: <PaperPreview key={paper.identifier} paper={paper} />,
          });
        });
      } else {
        // Other agent messages
        rebuiltConversation.push(msg);
      }
    });

    setMessages(rebuiltConversation);
  };

  const handleDialogueClick = (dialogue) => {
    loadDialogueState(dialogue);

    if (isMobile) {
      setMobileOpen(false);
    }
  };

  const renderContent = () => {
    if (activeTab === 'history') {
      if (!dialogues || dialogues.length === 0) {
        return (
          <Typography variant='body1' className='sidebar-default-content'>
            Currently you have no chat history.
          </Typography>
        );
      }
      return (
        <List className='sidebar-list'>
          {dialogues.map((dialogue) => {
            const userMessage = dialogue.conversation.find((msg) => msg.isUser);
            const displayText = userMessage
              ? userMessage.inputText
              : dialogue.conversation[0].inputText;
            return (
              <ListItem key={dialogue.dialogue_id} disablePadding className='sidebar-icons'>
                <ListItemButton
                  className='dialogue-item'
                  onClick={() => handleDialogueClick(dialogue)}
                >
                  <ListItemText primary={truncateTitle(displayText)} />
                </ListItemButton>
                <ListItemSecondaryAction>
                  <IconButton edge='end' onClick={(e) => handleMoreClick(e, dialogue.dialogue_id)}>
                    <MoreVertIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      );
    } else if (activeTab === 'bookmark') {
      if (loading) {
        return (
          <Typography variant='body1' className='sidebar-default-content'>
            Loading bookmarks...
          </Typography>
        );
      }
      if (!bookmarks || bookmarks.length === 0) {
        return (
          <Typography variant='body1' className='sidebar-default-content'>
            Currently you have no bookmarks saved.
          </Typography>
        );
      }
      return (
        <List className='sidebar-list'>
          {bookmarks.map((bookmark, idx) => (
            <ListItem key={idx} disablePadding className='bookmarks-list'>
              <ListItemButton
                onClick={() => handleBookmarkClick(bookmark)}
                className='bookmark-item'
              >
                <ListItemText primary={bookmark.title} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      );
    }
    return null;
  };

  const sidebarContent = (
    <div className={`sidebar ${expanded ? '' : 'collapsed'}`}>
      {isDesktop && expanded && (
        <div className='header'>
          <div className='sidebar-title'>
            <div>
              <span className='link-blue'>Materials</span> AI Agent{' '}
              <img
                alt='Materials Science Logo'
                className='logo'
                src={process.env.PUBLIC_URL + '/img/chatsymbol_transparent.png'}
              />{' '}
            </div>
          </div>
          <IconButton onClick={toggleDrawer} className='sidebar-button-color sidebar-toggle-button'>
            <MenuIcon />
          </IconButton>
        </div>
      )}
      {isDesktop && !expanded && (
        <div className='collapsed-icons'>
          <IconButton onClick={toggleDrawer} className='sidebar-button-color sidebar-toggle-button'>
            <MenuIcon />
          </IconButton>
          <IconButton onClick={handleStartNewConversation} className='sidebar-button-color'>
            <AddCircleOutlineIcon />
          </IconButton>
          <IconButton onClick={() => expandSidebar('history')} className='sidebar-button-color'>
            <HistoryIcon />
          </IconButton>
          <IconButton onClick={() => expandSidebar('bookmark')} className='sidebar-button-color'>
            <BookmarkIcon />
          </IconButton>
        </div>
      )}
      {(!isDesktop || expanded) && (
        <div className='sidebar-expanded-content'>
          {!isDesktop ? (
            <div className='sidebar-title'>
              <div>
                <span className='link-blue'>Materials</span> AI Agent
                <img
                  alt='Materials Science Logo'
                  className='header-logo'
                  src={process.env.PUBLIC_URL + '/img/chatsymbol_transparent.png'}
                />
              </div>
            </div>
          ) : null}
          <div className='sidebar-start-conversation'>
            <Button
              onClick={handleStartNewConversation}
              className='custom-sidebar-button start-conversation-button'
              disableRipple
            >
              <AddCircleOutlineIcon className='sidebar-icon' />
              <span className='button-label'>New Chat</span>
            </Button>
          </div>
          <div className='buttons-container'>
            <Button
              onClick={() => setActiveTab('history')}
              className={`custom-sidebar-button ${activeTab === 'history' ? 'active' : ''}`}
              disableRipple
            >
              <HistoryIcon className='sidebar-icon' />
              <span className='button-label'>History</span>
            </Button>
            <Button
              onClick={() => setActiveTab('bookmark')}
              className={`custom-sidebar-button ${activeTab === 'bookmark' ? 'active' : ''}`}
              disableRipple
            >
              <BookmarkIcon className='sidebar-icon' />
              <span className='button-label'>Bookmarks</span>
            </Button>
            <div
              className='underline-indicator'
              style={{ left: activeTab === 'history' ? '0%' : '50%' }}
            />
          </div>
          <div className='sidebar-scrollable-content'>
            <div className='sidebar-content'>{renderContent()}</div>
          </div>
          <div className='sidebar-bottom-row'>
            <div className='footer-links'>
              <Link to='/about' className='sidebar-link'>
                About
              </Link>
              <Link to='/imprint' className='sidebar-link'>
                Imprint
              </Link>
              <Link to='/privacy' className='sidebar-link'>
                Privacy
              </Link>
            </div>
            <div className='footer-copyright'>
              <span className='footer-copyright-icon'>©</span>
              {new Date().getFullYear()} Materials AI Agent
            </div>
          </div>{' '}
        </div>
      )}
    </div>
  );

  return (
    <>
      {!isDesktop && (
        <IconButton className='mobile-toggle sidebar-toggle-button' onClick={toggleDrawer}>
          <MenuIcon />
        </IconButton>
      )}
      {isDesktop ? (
        <Drawer variant='persistent' anchor='left' open>
          {sidebarContent}
        </Drawer>
      ) : (
        <Drawer
          variant='temporary'
          anchor='left'
          open={mobileOpen}
          onClose={toggleDrawer}
          transitionDuration={{ enter: 300, exit: 300 }}
          PaperProps={{ style: { width: '80%' } }}
        >
          {sidebarContent}
        </Drawer>
      )}
      {showModal && selectedBookmark && (
        <PaperModal
          data={selectedBookmark}
          onClose={() => {
            setShowModal(false);
            setSelectedBookmark(null);
          }}
          isPaperSavedAsBookmark={true}
          updateBookmarks={refreshBookmarks}
        />
      )}
      <OptionsModal
        open={Boolean(optionsAnchorEl)}
        anchorEl={optionsAnchorEl}
        onClose={handleCloseOptions}
        onDelete={handleDeleteDialogue}
        dialogueId={selectedDialogueId}
      />
    </>
  );
}
